import CasinoIcon from '@mui/icons-material/Casino';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import LockClockIcon from '@mui/icons-material/LockClock';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PinIcon from '@mui/icons-material/Pin';
import RemoveIcon from '@mui/icons-material/Remove';
import SettingsIcon from '@mui/icons-material/Settings';
import SyncIcon from '@mui/icons-material/Sync';
import TagIcon from '@mui/icons-material/Tag';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import {
  Box,
  Card,
  IconButton,
  Tooltip,
  Typography,
  keyframes,
  useTheme,
  Unstable_Grid2 as Grid2,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { TTLOCK_QUERY_KEY } from 'apis-query/ttlock.query';
import { deletePassCode, editPassCode, getListPassCode } from 'apis/ttlock.api';
import FormikDateRangePicker from 'components/Formik/FormikDateRangePicker';
import FormikInput from 'components/Formik/FormikInput';
import { Loader } from 'components/LoadingIndicator';
import NumericFormatInput from 'components/NumericFormatInput';
import SuiBox from 'components/SuiBox';
import SuiModal, { SuiModalProps } from 'components/SuiModal';
import { format, isValid, set } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { BaseResponse } from 'interfaces/api-response.interface';
import { flatten } from 'lodash-es';
import {
  QUERY_KEYS,
  SERVER_RECORD_TYPE_PASSCODES,
} from 'pages/lockManagement/constants';
import { queryClient } from 'queryClient';
import { useCallback, useMemo, useRef, useState } from 'react';
import { Range } from 'react-date-range';
import { Waypoint } from 'react-waypoint';
import { PaginatedResponse } from 'shared/api/myla';
import { useLazyQuery } from 'shared/hooks/useLazyQuery';
import { PassCode } from 'shared/models';
import useMylaMutation from 'shared/packages/myla-query/hooks/useMutation';
import * as yup from 'yup';

interface PassCodeFormik {
  lockId: string;
  keyboardPwdId: string;
  keyboardPwdName: string;
  newKeyboardPwd: string;
  dateRange: Range[];
}
interface LockHistoriesProps
  extends Omit<SuiModalProps, 'open' | 'children' | 'title'> {
  lockId: number;
}

const passCodeFormValidationSchema = yup.object().shape({
  keyboardPwdName: yup.string().required('Vui lòng nhập tên mật khẩu'),
  newKeyboardPwd: yup.number(),
  dateRange: yup
    .mixed()
    .test('dateRange', 'Vui lòng chọn ngày', (value: any) => {
      if (!Array.isArray(value) || value.length === 0) return false;
      return value.every((v) => isValid(v.startDate) && isValid(v.endDate));
    }),
});

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

function PassCodeItem({
  passCode,
  onDeletePassCode,
  onOpenEditPassCode,
}: {
  passCode: PassCode;
  onDeletePassCode: (params: PassCode) => void;
  onOpenEditPassCode: (params: PassCode) => void;
}) {
  const {
    keyboardPwdId,
    keyboardPwd,
    keyboardPwdName,
    keyboardPwdType,
    startDate,
    endDate,
    isCustom,
    nickName,
    sendDate,
    lockId,
  } = passCode;
  return (
    <Card sx={{ p: 3, alignItems: 'center', flexDirection: 'row' }}>
      <Box
        flex={1}
        rowGap={1}
        display="flex"
        flexDirection="column"
        sx={{
          color: 'text.secondary',
        }}
      >
        <Typography display="flex" alignItems="center">
          <LockClockIcon fontSize="small" sx={{ mr: 1.5 }} />
          <Typography
            display="flex"
            alignItems="center"
            variant="subtitle2"
            component="span"
          >
            {startDate
              ? format(new Date(startDate), 'HH:ss dd/MM/yyyy')
              : 'N/A'}
            &nbsp; <RemoveIcon fontSize="small" /> &nbsp;
            {endDate ? format(new Date(endDate), 'HH:ss dd/MM/yyyy') : 'N/A'}
          </Typography>
        </Typography>
        <SuiBox display="flex" alignItems="center">
          <TagIcon fontSize="medium" sx={{ mr: 1.5 }} />
          <Typography variant="subtitle2" component="span">
            {keyboardPwdName}
          </Typography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <PinIcon fontSize="medium" sx={{ mr: 1.5 }} />
          {keyboardPwd && (
            <Typography component="span">
              <Tooltip title={keyboardPwd}>
                <b>{`${keyboardPwd.slice(0, 3)}***`}</b>
              </Tooltip>
            </Typography>
          )}
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <WorkHistoryIcon fontSize="medium" sx={{ mr: 1.5 }} />
          <Typography variant="subtitle2" component="span">
            {isValid(sendDate)
              ? format(new Date(sendDate), 'HH:ss dd/MM/yyyy')
              : ''}
          </Typography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <ManageAccountsIcon fontSize="medium" sx={{ mr: 1.5 }} />
          <Typography variant="subtitle2" component="span">
            {nickName}
          </Typography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <CasinoIcon fontSize="medium" sx={{ mr: 1.5 }} />
          <Typography variant="subtitle2" component="span">
            {isCustom ? 'Mật khẩu tự tạo' : 'Mật khẩu ngẫu nhiên'}
          </Typography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <HourglassTopIcon fontSize="medium" sx={{ mr: 1.5 }} />
          <Typography variant="subtitle2" component="span">
            {SERVER_RECORD_TYPE_PASSCODES[keyboardPwdType]}
          </Typography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <SettingsIcon fontSize="medium" sx={{ mr: 1.5 }} />
          <IconButton
            onClick={() => onOpenEditPassCode(passCode)}
            size="medium"
            color="success"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => onDeletePassCode(passCode)}
            size="medium"
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </SuiBox>
      </Box>
    </Card>
  );
}

function UpdatePassCodeModal({
  passCode,
  onEditPassCode,
  onClose,
  loading,
}: {
  passCode: PassCode;
  onEditPassCode: (values: PassCodeFormik) => void;
  onClose: () => void;
  loading: boolean;
}) {
  const formikRef = useRef<FormikProps<PassCodeFormik> | null>(null);
  const initFormValues = useMemo(() => {
    return {
      lockId: `${passCode.lockId}`,
      keyboardPwdId: `${passCode.keyboardPwdId}`,
      keyboardPwdName: passCode.keyboardPwdName,
      newKeyboardPwd: '',
      dateRange: [
        {
          key: 'dateRange',
          startDate: new Date(passCode?.startDate),
          endDate: new Date(passCode?.endDate),
        },
      ],
    };
  }, [passCode]);

  const handleUpdatePassCode = useCallback(
    (values: PassCodeFormik) => {
      onEditPassCode(values);
    },
    [onEditPassCode]
  );

  const handleOnclose = useCallback(
    (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={handleUpdatePassCode}
      initialValues={initFormValues}
      innerRef={formikRef}
      validationSchema={passCodeFormValidationSchema}
    >
      <SuiModal
        showCloseIcon={false}
        backText="Về trang trước"
        open
        confirmText="Cập nhật"
        cancelText="Thoát"
        onConfirm={() => formikRef.current?.submitForm()}
        title="Cập nhật khoá phòng"
        loadingOverlay={loading}
        onClose={handleOnclose}
        onCancel={onClose}
      >
        <SuiBox>
          <SuiBox
            sx={{
              borderTop: '1px dashed #BDBDBD',
            }}
            pt={1.5}
          >
            <Grid2
              container
              mb={3}
              width="100%"
              rowSpacing={{ xs: 2 }}
              columnSpacing={{ xs: 1, md: 2 }}
            >
              <Grid2 xs={12} md={12}>
                <FormikInput
                  name="keyboardPwdName"
                  placeholder="Nhập tên mật khẩu"
                  label="Tên mật khẩu"
                />
              </Grid2>
              <Grid2 xs={12} md={12}>
                <FormikInput
                  name="newKeyboardPwd"
                  placeholder="Nhập mật khẩu mới"
                  label="Mật khẩu mới"
                  inputComponent={NumericFormatInput as any}
                  inputProps={{
                    inputMode: 'numeric',
                  }}
                />
              </Grid2>

              <Grid2 xs={12} md={12}>
                <FormikDateRangePicker name="dateRange" label="Chọn ngày" />
              </Grid2>
            </Grid2>
          </SuiBox>
        </SuiBox>
      </SuiModal>
    </Formik>
  );
}

function LockPassCodeList({ lockId, ...modalProps }: LockHistoriesProps) {
  const theme = useTheme();
  const [isOpenUpdatePassCode, setIsOpenUpdatePassCode] = useState(false);
  const [passCode, setPassCode] = useState<PassCode>();

  const { lazyQuery: deletePassCodeMutation, isFetching: isDeletePassCode } =
    useLazyQuery<BaseResponse, { keyboardPwdId: string; lockId: string }>({
      fetcher: (params) => deletePassCode(params || {}),
      queryKey: [TTLOCK_QUERY_KEY.DELETE_PASSCODE],
      onSuccess() {
        queryClient.invalidateQueries([QUERY_KEYS.LIST_PASSCODES]);
      },
    });

  const handleCloseUpdatePassCodeModal = useCallback(() => {
    setIsOpenUpdatePassCode(false);
    setPassCode(undefined);
  }, []);

  const { mutate: editPassCodeMutation, isLoading: isEditPassCode } =
    useMylaMutation<BaseResponse, BaseResponse, Record<string, unknown>>({
      mutationFn: (params) => editPassCode(params || {}),
      onSuccess() {
        queryClient.invalidateQueries([QUERY_KEYS.LIST_PASSCODES]);
        handleCloseUpdatePassCodeModal();
      },
      notify: {
        success: 'Cập nhật mật khẩu thành công',
        error: (error) => ({
          message: error?.message || 'Cập nhật mật khẩu thất bại',
        }),
        loading: 'Đang cập nhật mật khẩu',
      },
    });

  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<PaginatedResponse<PassCode>>({
    queryKey: [QUERY_KEYS.LIST_PASSCODES],
    queryFn: ({ pageParam = { pageNo: 1, pageSize: 10 } }) =>
      getListPassCode({ lockId, ...pageParam }),
    getNextPageParam: ({ meta }) =>
      meta.hasNextPage
        ? { pageNo: meta.page + 1, pageSize: meta.take }
        : undefined,
  });

  const listPassCode = useMemo(
    () => flatten(data?.pages.map((page) => page.data)),
    [data]
  );

  const handleDeletePassCode = useCallback(
    (params: PassCode) => {
      deletePassCodeMutation({
        keyboardPwdId: `${params.keyboardPwdId}`,
        lockId: `${params.lockId}`,
      });
    },
    [deletePassCodeMutation]
  );
  const handleOpenEditPassCode = useCallback((pass: PassCode) => {
    setPassCode(pass);
    setIsOpenUpdatePassCode(true);
  }, []);

  const handleEditPassCode = useCallback(
    (values: PassCodeFormik) => {
      const { keyboardPwdName, newKeyboardPwd, dateRange } = values;
      const { startDate, endDate } = dateRange[0];
      const params = {
        keyboardPwdId: passCode?.keyboardPwdId,
        lockId: passCode?.lockId,
        keyboardPwdName,
        newKeyboardPwd,
        startDate: set(startDate?.getTime() || new Date(), {
          hours: 14,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }).getTime(),
        endDate: set(endDate?.getTime() || new Date(), {
          hours: 12,
          minutes: 0,
          seconds: 0,
          milliseconds: 0,
        }).getTime(),
      };
      editPassCodeMutation(params);
    },
    [editPassCodeMutation, passCode?.keyboardPwdId, passCode?.lockId]
  );

  return (
    <SuiModal
      open={!isOpenUpdatePassCode}
      hideFooter
      title="Danh sách mật khẩu"
      desktopHeight="80dvh"
      desktopWidth={theme.spacing(120)}
      modalActions={
        <IconButton
          onClick={() => {
            if (!isFetching) {
              refetch();
            }
          }}
        >
          <SyncIcon
            sx={{
              animation: isFetching ? `${spin} 1s infinite ease` : undefined,
            }}
          />
        </IconButton>
      }
      className="loading"
      loadingOverlay={isDeletePassCode}
      {...modalProps}
      keepMounted={isOpenUpdatePassCode}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          pt: 2,
          pb: 4,
        }}
      >
        {listPassCode.map((p) => {
          return (
            <PassCodeItem
              passCode={p}
              onDeletePassCode={handleDeletePassCode}
              onOpenEditPassCode={handleOpenEditPassCode}
              key={p.keyboardPwdId}
            />
          );
        })}
        {isOpenUpdatePassCode && passCode && (
          <UpdatePassCodeModal
            passCode={passCode}
            onEditPassCode={handleEditPassCode}
            onClose={handleCloseUpdatePassCodeModal}
            loading={isEditPassCode}
          />
        )}
        {isFetching && <Loader />}
        {hasNextPage && (
          <Waypoint
            onEnter={() => {
              if (!isFetching && !isFetchingNextPage) {
                fetchNextPage();
              }
            }}
          />
        )}
      </Box>
    </SuiModal>
  );
}

export default LockPassCodeList;
