import CreditCardIcon from '@mui/icons-material/CreditCard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpIcon from '@mui/icons-material/Help';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import LockIcon from '@mui/icons-material/Lock';
import LockClockIcon from '@mui/icons-material/LockClock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import {
  Box,
  Card,
  Chip,
  IconButton,
  LinearProgress,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  linearProgressClasses,
  styled,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { compact } from 'lodash-es';
import { QUERY_KEYS } from 'pages/lockManagement/constants';
import { useCallback, useMemo, useState } from 'react';
import mylaClient from 'shared/api/myla';
import { ITTLockDevice, LockState } from 'shared/models';
import { Device } from 'shared/models/device.model';
import { useMutation } from 'shared/packages/myla-query';
import SuiBadge from 'components/SuiBadge';
import LockICCardList from '../LockIdentityCard/LockICCardList';
import LockHistories from './LockHistories';
import LockPassCodeList from './LockPassCodeList';
import StatusDot from './StatusDot';

const BorderLinearProgress = styled(LinearProgress)(({ theme, value = 0 }) => ({
  height: 12,
  borderRadius: 16,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    height: 12,
    borderRadius: 16,
    background: value > 10 ? theme.palette.blue.main : theme.palette.error.main,
  },
}));

const getStateProps = (state?: LockState) => {
  switch (state) {
    case LockState.LOCKED:
      return {
        icon: <LockIcon />,
        text: 'Đã khoá',
      };
    case LockState.UNLOCKED:
      return {
        icon: <LockOpenIcon />,
        text: 'Đang mở khoá',
      };
    default:
      return {
        icon: <HelpIcon />,
        text: 'Không rõ',
      };
  }
};

function LockCard(device: Device<ITTLockDevice>) {
  const queryClient = useQueryClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpenConfig, setMenuOpenConfig] = useState<{
    openHistories: boolean;
    openPassCodeList: boolean;
    openListICCard: boolean;
  }>({
    openHistories: false,
    openPassCodeList: false,
    openListICCard: false,
  });

  const { name, metadata } = device;
  const { lockId, state, electricQuantity, childRoom } = metadata;
  const { mutate: changeLockState, isLoading } = useMutation<
    AxiosResponse,
    AxiosError<{
      message: string;
      statusCode: number;
    }>,
    LockState
  >({
    mutationFn: (lockState) =>
      mylaClient.post(
        `/user-devices/lock/${
          lockState === LockState.UNLOCKED ? 'lock' : 'unlock'
        }`,
        { lockId }
      ),
    onSuccess: () =>
      setTimeout(() => {
        queryClient.invalidateQueries([QUERY_KEYS.LOCK_LIST]);
        queryClient.invalidateQueries([QUERY_KEYS.LOCK_HISTORIES]);
      }, 2000),
    notify: {
      loading: 'Đang thực hiện tác vụ...',
      success: 'Thực hiện tác vụ thành công!',
      error: ({ message, response }) => ({
        message:
          response?.data?.message ||
          message ||
          'Tác vụ không thành công. Vui lòng thử lại sau hoặc liên hệ admin',
      }),
    },
  });

  const closeMenu = useCallback(() => setAnchorEl(null), []);
  const menuItems = useMemo(
    () =>
      compact([
        state === LockState.UNLOCKED && {
          icon: <LockIcon />,
          text: 'Đóng khoá',
          onClick: () => changeLockState(LockState.LOCKED),
        },
        state === LockState.LOCKED && {
          icon: <LockOpenIcon />,
          text: 'Mở khoá',
          onClick: () => changeLockState(LockState.LOCKED),
        },
        {
          icon: <KeyboardIcon />,
          text: 'Danh sách mật khẩu',
          onClick: () =>
            setMenuOpenConfig((prev) => ({
              ...prev,
              openPassCodeList: true,
            })),
        },
        {
          icon: <LockClockIcon />,
          text: 'Lịch sử mở khoá',
          onClick: () =>
            setMenuOpenConfig((prev) => ({
              ...prev,
              openHistories: true,
            })),
        },
        {
          icon: <CreditCardIcon />,
          text: 'Danh sách thẻ từ',
          onClick: () =>
            setMenuOpenConfig((prev) => ({
              ...prev,
              openListICCard: true,
            })),
        },
      ]),
    [changeLockState, state]
  );

  const stateProps = getStateProps(state);

  return (
    <>
      <Card
        sx={{
          p: 2,
          gap: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      >
        <Stack
          mb={1}
          direction="row"
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            flex={2}
            variant="h4"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            title={name}
            fontWeight={400}
          >
            {name}
            <SuiBadge
              color="dark"
              variant="gradient"
              max={Number.MAX_SAFE_INTEGER}
              sx={{ ml: 1, fontWeight: 600 }}
              badgeContent={childRoom?.name?.toString() || 'Còn trống'}
            />
          </Typography>
          <StatusDot
            size={16}
            status={state === LockState.UNKNOWN ? 'offline' : 'online'}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{ width: '100%' }}
        >
          <Tooltip title={`${electricQuantity}%`}>
            <BorderLinearProgress
              value={electricQuantity}
              variant="determinate"
              sx={{ flex: 2 }}
            />
          </Tooltip>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          <Chip
            icon={stateProps.icon}
            color="warning"
            variant="filled"
            size="small"
            label={stateProps.text}
          />
          <Box>
            {!!menuItems.length && (
              <>
                <IconButton
                  edge="end"
                  size="medium"
                  disabled={isLoading}
                  sx={{ alignSelf: 'flex-end' }}
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                  <ExpandMoreIcon />
                </IconButton>
                <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={closeMenu}>
                  {menuItems.map(({ text, icon, onClick }) => (
                    <MenuItem
                      key={text}
                      onClick={() => {
                        onClick();
                        closeMenu();
                      }}
                    >
                      <ListItemIcon>{icon}</ListItemIcon>
                      <ListItemText>{text}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </>
            )}
          </Box>
        </Stack>
      </Card>
      {menuOpenConfig.openHistories && (
        <LockHistories
          key={lockId}
          lockId={lockId}
          onClose={() =>
            setMenuOpenConfig((prev) => ({ ...prev, openHistories: false }))
          }
        />
      )}
      {menuOpenConfig.openPassCodeList && (
        <LockPassCodeList
          key={lockId}
          lockId={lockId}
          onClose={() =>
            setMenuOpenConfig((prev) => ({
              ...prev,
              openPassCodeList: false,
            }))
          }
        />
      )}

      {menuOpenConfig.openListICCard && (
        <LockICCardList
          key={lockId}
          lockId={lockId}
          lockName={device.name}
          onClose={() =>
            setMenuOpenConfig((prev) => ({ ...prev, openListICCard: false }))
          }
        />
      )}
    </>
  );
}

export default LockCard;
