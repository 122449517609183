import { BaseResponse } from 'interfaces/api-response.interface';
import mylaClient, { PaginatedResponse } from 'shared/api/myla';
import { API_PATHS } from 'shared/constants';
import { ICIdentityCard, ITTLockDevice, PassCode } from 'shared/models';
import { Device } from 'shared/models/device.model';

export async function getListPassCode(params: Record<string, any>) {
  const res = await mylaClient.get<PaginatedResponse<PassCode>>(
    `${API_PATHS.TTLOCK.LIST_PASSCODE}`,
    {
      params,
    }
  );
  return res.data;
}

export async function getListDeviceAvailable(params?: Record<string, any>) {
  const res = await mylaClient.get<Device<ITTLockDevice>[]>(
    `${API_PATHS.TTLOCK.LIST_DEVICE_AVAILABLE}`,
    {
      params,
    }
  );
  return res.data;
}

export async function getListDevice(params?: Record<string, any>) {
  const res = await mylaClient.get<PaginatedResponse<Device<ITTLockDevice>>>(
    `${API_PATHS.TTLOCK.LIST_DEVICE}`,
    {
      params,
    }
  );
  return res.data;
}

export async function editPassCode(params: Record<string, unknown>) {
  const res = await mylaClient.post<BaseResponse>(
    `${API_PATHS.TTLOCK.EDIT_PASSCODE}`,
    params
  );
  return res.data;
}

export async function deletePassCode(params: Record<string, unknown>) {
  const res = await mylaClient.post<BaseResponse>(
    `${API_PATHS.TTLOCK.DELETE_PASSCODE}`,
    params
  );
  return res.data;
}

export async function getListICCard(params: {
  lockId: string;
  pageNo: number;
  pageSize: number;
}) {
  const res = await mylaClient.get<PaginatedResponse<ICIdentityCard>>(
    `${API_PATHS.IC_CARD.LIST}`,
    {
      params,
    }
  );
  return res.data;
}

export async function addICCard(params: Partial<ICIdentityCard>) {
  const res = await mylaClient.post(`${API_PATHS.IC_CARD.ADD}`, params);
  return res.data;
}

export async function updateICCard(params: any) {
  const res = await mylaClient.post(
    `${API_PATHS.IC_CARD.CHANGE_PERIOD}`,
    params
  );
  return res.data;
}
export async function deleteICCard(
  params: { lockId: string; cardId: string } | null
) {
  const res = await mylaClient.post<BaseResponse>(
    `${API_PATHS.IC_CARD.DELETE}`,
    {
      ...params,
      deleteType: 2,
    }
  );
  return res.data;
}

export async function renameNameICCard(params: Record<string, any>) {
  const res = await mylaClient.post(`${API_PATHS.IC_CARD.RENAME_NAME}`, params);
  return res.data;
}

export async function addCardFromOrder(params: {
  cardNumber?: string;
  orderId?: string;
}) {
  const res = await mylaClient.post<{
    message: string;
    statusCode: number;
  }>(`${API_PATHS.TTLOCK.ADD_CARD_FROM_ORDER}`, params);
  return res.data;
}
