import { useField } from 'formik';
import { DateRangeProps } from 'react-date-range';
import DateRangePicker from '../DateRangePicker';
import { SuiInputProps } from '../SuiInput';
import { BaseFieldProps } from './types';

interface FormikInputProps
  extends BaseFieldProps,
    Pick<
      DateRangeProps,
      'disabledDates' | 'minDate' | 'maxDate' | 'disabledDay'
    > {
  SuiInputProps?: Partial<Omit<SuiInputProps, 'onChange' | 'value' | 'ref'>>;
}

function FormikDateRangePicker({
  name,
  helperText,
  disabled,
  placeholder,
  label = placeholder,
  hideSuccessIndicator = false,

  ...props
}: FormikInputProps) {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;
  const isError = !!error && touched;

  return (
    <DateRangePicker
      {...field}
      {...props}
      name={name}
      error={isError}
      label={label}
      onChange={helpers.setValue}
      placeholder={placeholder}
      success={!hideSuccessIndicator && touched && !error}
      SuiInputProps={{
        helperText: isError ? error : helperText,
        error: isError,
      }}
    />
  );
}

export default FormikDateRangePicker;
