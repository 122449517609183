// clsx is a utility for constructing className strings conditionally
import clsx from 'clsx';

// Custom styles for the SuiBadge
import styles from 'components/SuiBadge/styles';

// prop-types is a library for typechecking of props
import React, { forwardRef } from 'react';

import { Badge, BadgeProps } from '@mui/material';

interface SuiBadgeProps extends Omit<BadgeProps, 'color' | 'variant' | 'size'> {
  color?:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'error'
    | 'light'
    | 'dark';
  variant?: 'gradient' | 'contained';
  size?: 'extra-small' | 'small' | 'medium' | 'large';
  badgeContent: React.ReactNode;
  circular?: boolean;
  indicator?: boolean;
  border?: boolean;
  children?: React.ReactNode;
  container?: boolean;
  max?: number;
}

const SuiBadge = forwardRef<HTMLSpanElement, SuiBadgeProps>(
  (
    {
      badgeContent,
      children,
      color = 'info',
      variant = 'gradient',
      size = 'small',
      circular = false,
      indicator = false,
      border = false,
      container = false,
      ...rest
    },
    ref
  ) => {
    const classes = styles({ color, circular, border, size });

    return (
      <Badge
        {...rest}
        ref={ref}
        badgeContent={badgeContent}
        color="default"
        className={clsx(`${classes[variant]}`, {
          [classes.badge]: !indicator,
          [classes.badge_indicator]: indicator,
          [classes.badge_childNode]: !children && !container,
          [classes.badge_container]: container,
        })}
      >
        {children}
      </Badge>
    );
  }
);

export default SuiBadge;
