import {
  AutocompleteInputChangeReason,
  AutocompleteValue,
} from '@mui/material';
import { useField } from 'formik';
import { get, uniqBy } from 'lodash-es';
import {
  FocusEventHandler,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import SuiSelect, { BaseOption, SuiSelectProps } from '../SuiSelect';
import { BaseFieldProps } from './types';

interface FormikSelectProps<
  Option extends BaseOption,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false
> extends BaseFieldProps,
    Partial<SuiSelectProps<Option, Multiple, DisableClearable, FreeSolo>> {
  freeSolo?: FreeSolo;
}

const getSelectedOptions = <
  Option extends BaseOption = BaseOption,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = true,
  FreeSolo extends boolean | undefined = false
>({
  selectedValue,
  options,
  freeSolo,
}: {
  selectedValue: Option['value'] | Option['value'][] | string;
  options: readonly Option[];
  freeSolo?: FreeSolo;
}) => {
  if (Array.isArray(selectedValue)) {
    return options.filter((option) =>
      selectedValue.includes(option.value)
    ) as AutocompleteValue<Option, Multiple, DisableClearable, FreeSolo>;
  }

  return (options.find((option) => selectedValue === option.value) ??
    (freeSolo ? selectedValue : undefined)) as AutocompleteValue<
    Option,
    Multiple,
    DisableClearable,
    FreeSolo
  >;
};

function FormikSelect<
  Option extends BaseOption = BaseOption,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = true,
  FreeSolo extends boolean | undefined = false
>({
  name,
  helperText,
  placeholder,
  onInputChange,
  SuiInputProps: suiInputProps,
  label = placeholder,
  multiple = false as Multiple,
  disableClearable = true as DisableClearable,
  disabled = false,
  freeSolo = false as FreeSolo,
  options = [],
  disableCloseOnSelect = false,
  ...props
}: FormikSelectProps<Option, Multiple, DisableClearable, FreeSolo>) {
  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue } = helpers;
  const { error, touched } = meta;
  const isError = !!error && touched;
  const [inputValue, setInputValue] = useState('');
  const [selected, setSelected] = useState<
    AutocompleteValue<Option, Multiple, DisableClearable, FreeSolo> | undefined
  >(
    getSelectedOptions<Option, Multiple, DisableClearable, FreeSolo>({
      selectedValue: value,
      options,
      freeSolo,
    })
  );
  const previousValueRef = useRef(value);

  const hasBeenHandledByOnChangeRef = useRef(false);
  const handleChange = useCallback(
    (
      _: SyntheticEvent<Element, Event>,
      changeValue: AutocompleteValue<
        Option,
        Multiple,
        DisableClearable,
        FreeSolo
      >
    ) => {
      hasBeenHandledByOnChangeRef.current = true;
      setSelected(changeValue);
      setInputValue('');
      if (multiple) {
        setValue((changeValue as Array<Option>).map((option) => option.value));
      } else {
        setValue((changeValue as Option)?.value);
      }
    },
    [multiple, setValue]
  );
  const optionsWithSelected = useMemo(() => {
    if (typeof selected !== 'object') {
      return options;
    }

    const selectedOptions = (
      Array.isArray(selected) ? selected : [selected]
    ) as Option[];

    return uniqBy([...selectedOptions, ...options], 'value');
  }, [options, selected]);

  const handleBlur: FocusEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      if (hasBeenHandledByOnChangeRef.current) {
        hasBeenHandledByOnChangeRef.current = false;
        return;
      }

      const currentInputValue = e.target.value;
      if (!currentInputValue || !freeSolo || Array.isArray(selected)) {
        return;
      }
      if (currentInputValue !== get(selected, 'value')) {
        setSelected(
          currentInputValue as AutocompleteValue<
            Option,
            Multiple,
            DisableClearable,
            FreeSolo
          >
        );
        setValue(currentInputValue);
        setInputValue('');
      }
    },
    [freeSolo, selected, setValue]
  );

  const handleInputChange = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      newInputValue: string,
      reason: AutocompleteInputChangeReason
    ) => {
      onInputChange?.(event, newInputValue, reason);
      setInputValue(newInputValue);
    },
    [onInputChange]
  );

  useEffect(() => {
    if (previousValueRef.current === value) {
      return;
    }

    previousValueRef.current = value;
    setSelected(
      getSelectedOptions<Option, Multiple, DisableClearable, FreeSolo>({
        selectedValue: value,
        options,
        freeSolo,
      })
    );
  }, [value]);

  return (
    <SuiSelect<Option, Multiple, DisableClearable, FreeSolo>
      {...props}
      label={label}
      value={selected}
      freeSolo={freeSolo}
      multiple={multiple}
      disabled={disabled}
      blurOnSelect={!disableCloseOnSelect}
      disableCloseOnSelect={disableCloseOnSelect}
      options={optionsWithSelected}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onChange={handleChange}
      placeholder={placeholder}
      disableClearable={disableClearable}
      onBlur={handleBlur}
      SuiInputProps={{
        error: isError,
        helperText: isError ? error : helperText,
      }}
    />
  );
}

export default FormikSelect;
