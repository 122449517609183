import { useQuery } from '@tanstack/react-query';
import { getListPassCode } from 'apis/ttlock.api';
import { PaginatedResponse } from 'shared/api/myla';
import { PassCode } from 'shared/models';

export const TTLOCK_QUERY_KEY = {
  GET_LIST_PASSCODE: 'GET_LIST_PASSCODE',
  DELETE_PASSCODE: 'DELETE_PASSCODE',
  EDIT_PASSCODE: 'EDIT_PASSCODE',
  GET_LIST_IC_CARD: 'GET_LIST_IC_CARD',
  DELETE_IC_CARD: 'DELETE_IC_CARD',
  EDIT_IC_CARD: 'EDIT_IC_CARD',
};

const useGetListPassCode = (params: Record<string, any>) =>
  useQuery<PaginatedResponse<PassCode>>({
    queryKey: [TTLOCK_QUERY_KEY.GET_LIST_PASSCODE],
    queryFn: () => getListPassCode(params),
  });

export { useGetListPassCode };
