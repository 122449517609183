import { Box, alpha } from '@mui/material';

const STATUS_COLORS = {
  online: '#77DD77',
  offline: '#D1D1D1',
} as const;

function StatusDot({
  status,
  size = 24,
}: {
  status: 'online' | 'offline';
  size?: number;
}) {
  const color = STATUS_COLORS[status];

  return (
    <Box
      sx={{
        position: 'relative',
        width: size,
        height: size,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          width: size * 0.5,
          height: size * 0.5,
          zIndex: 2,
          borderRadius: '50%',
          bgcolor: color,
        }}
      />
      <Box
        sx={{
          width: size,
          height: size,
          position: 'absolute',
          top: '50%',
          left: '50%',
          bgcolor: alpha(color, 0.4),
          transform: 'translate(-50%, -50%)',
        }}
      />
    </Box>
  );
}

export default StatusDot;
