import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import { Box, IconButton, keyframes, useTheme } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import { TTLOCK_QUERY_KEY } from 'apis-query/ttlock.query';
import {
  addICCard,
  deleteICCard,
  getListICCard,
  renameNameICCard,
  updateICCard,
} from 'apis/ttlock.api';
import { Loader } from 'components/LoadingIndicator';
import SuiBox from 'components/SuiBox';
import SuiModal, { SuiModalProps } from 'components/SuiModal';
import { BaseResponse } from 'interfaces/api-response.interface';
import { flatten } from 'lodash-es';
import { QUERY_KEYS } from 'pages/lockManagement/constants';
import { queryClient } from 'queryClient';
import { useCallback, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { PaginatedResponse } from 'shared/api/myla';
import { useLazyQuery } from 'shared/hooks/useLazyQuery';
import { ICIdentityCard } from 'shared/models';
import useMylaMutation from 'shared/packages/myla-query/hooks/useMutation';
import Swal from 'sweetalert2';
import LockICCardFormUpdate, {
  ICIdentityCardFormik,
} from './LockICCardFormUpdate';
import LockICCardItem from './LockICCardItem';
import LockICCardFormCreate from './LockICCardFormCreate';

interface LockICListProps
  extends Omit<SuiModalProps, 'open' | 'children' | 'title'> {
  lockId: number;
  lockName: string;
}

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

function LockICCardList({ lockId, lockName, ...modalProps }: LockICListProps) {
  const [isOpenUpdateICCard, setIsOpenUpdateICCard] = useState(false);
  const [isOpenAddICCard, setIsOpenAddICCard] = useState(false);
  const [iCIdentityCard, setICIdentityCard] = useState<ICIdentityCard>();

  const theme = useTheme();
  const {
    data,
    hasNextPage,
    isFetching,
    fetchNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery<PaginatedResponse<ICIdentityCard>>({
    queryKey: [TTLOCK_QUERY_KEY.GET_LIST_IC_CARD],
    queryFn: ({ pageParam = { pageNo: 1, pageSize: 10 } }) =>
      getListICCard({
        ...pageParam,
        lockId,
      }),
    getNextPageParam: ({ meta }) =>
      meta.hasNextPage
        ? { pageNo: meta.page + 1, pageSize: meta.take }
        : undefined,
  });

  const { lazyQuery: deleteICCardMutation, isFetching: isDeleteICCard } =
    useLazyQuery<BaseResponse, { lockId: string; cardId: string }>({
      fetcher: (params) => deleteICCard(params),
      queryKey: [TTLOCK_QUERY_KEY.DELETE_IC_CARD],
      onSuccess() {
        queryClient.invalidateQueries([QUERY_KEYS.IC_CARD_LIST]);
      },
    });

  const handleCloseEditICCardModal = useCallback(() => {
    setIsOpenUpdateICCard(false);
    setICIdentityCard(undefined);
  }, []);

  const { mutate: addICCardMutation, isLoading: isAddICCard } = useMylaMutation<
    BaseResponse,
    BaseResponse,
    Partial<ICIdentityCard>
  >({
    mutationFn: (params) => addICCard(params || {}),
    onSuccess() {
      queryClient.invalidateQueries([TTLOCK_QUERY_KEY.GET_LIST_IC_CARD]);
      setIsOpenAddICCard(false);
    },
    notify: {
      success: 'Tạo thẻ từ thành công',
      error: (error) => ({
        message: error?.message || 'Tạo thẻ từ thất bại',
      }),
      loading: 'Đang tạo thẻ từ',
    },
  });

  const { mutate: editICCardMutation, isLoading: isEditICCard } =
    useMylaMutation<BaseResponse, BaseResponse, Record<string, unknown>>({
      mutationFn: (params) => updateICCard(params || {}),
      onSuccess() {
        queryClient.invalidateQueries([TTLOCK_QUERY_KEY.GET_LIST_IC_CARD]);
        handleCloseEditICCardModal();
      },
      notify: {
        success: 'Cập nhật thẻ từ thành công',
        error: (error) => ({
          message: error?.message || 'Cập nhật thẻ từ thất bại',
        }),
        loading: 'Đang cập nhật thẻ từ',
      },
    });

  const { mutate: renameICCardMutation, isLoading: isRenameICCard } =
    useMylaMutation<BaseResponse, BaseResponse, Record<string, unknown>>({
      mutationFn: (params) => renameNameICCard(params || {}),
      onSuccess() {
        queryClient.invalidateQueries([TTLOCK_QUERY_KEY.GET_LIST_IC_CARD]);
        handleCloseEditICCardModal();
      },
      notify: {
        success: 'Cập nhật tên thẻ từ thành công',
        error: (error) => ({
          message: error?.message || 'Cập nhật tên thẻ từ thất bại',
        }),
        loading: 'Đang cập nhật tên thẻ từ',
      },
    });

  const listICCard = useMemo(
    () => flatten(data?.pages.map((page) => page.data)),
    [data]
  );

  const handleDeleteICCard = useCallback(
    (icCard: ICIdentityCard) => {
      Swal.mixin({
        customClass: {
          confirmButton: 'button button-error',
          cancelButton: 'button',
        },
        buttonsStyling: false,
      })
        .fire({
          title: `Bạn có chắc chắn xoá thẻ từ này không?`,
          confirmButtonText: 'Xoá',
          cancelButtonText: 'Huỷ bỏ',
          showCancelButton: true,
          allowOutsideClick: true,
          focusConfirm: false,
          keydownListenerCapture: true,
        })
        .then(({ isConfirmed }) => {
          if (isConfirmed) {
            deleteICCardMutation({
              lockId: icCard.lockId.toString(),
              cardId: icCard.cardId.toString(),
            });
          }
        });
    },
    [deleteICCardMutation]
  );

  const handleAddICCard = useCallback(
    (newIcCard: Partial<ICIdentityCard>) => {
      addICCardMutation(newIcCard);
    },
    [addICCardMutation]
  );

  const handleUpdateICCard = useCallback(
    (icCard: ICIdentityCardFormik) => {
      if (icCard.cardName !== iCIdentityCard?.cardName) {
        renameICCardMutation({
          cardName: icCard.cardName,
          cardId: icCard.cardId,
          lockId: icCard.lockId,
        });
      }
      if (
        icCard.startDate.getTime() !== iCIdentityCard?.startDate ||
        icCard.endDate.getTime() !== iCIdentityCard?.endDate
      ) {
        editICCardMutation({
          cardName: icCard.cardName,
          cardId: icCard.cardId,
          lockId: icCard.lockId,
          startDate: icCard.startDate.getTime(),
          endDate: icCard.endDate.getTime(),
        });
      }
    },
    [
      editICCardMutation,
      iCIdentityCard?.cardName,
      iCIdentityCard?.endDate,
      iCIdentityCard?.startDate,
      renameICCardMutation,
    ]
  );

  const handleOpenEditICCard = useCallback((icCard: ICIdentityCard) => {
    setICIdentityCard(icCard);
    setIsOpenUpdateICCard(true);
  }, []);

  return (
    <SuiModal
      open
      hideFooter
      className="loading"
      loadingOverlay={isDeleteICCard || isRenameICCard}
      title="Danh sách thẻ từ"
      desktopHeight="80dvh"
      desktopWidth={theme.spacing(120)}
      modalActions={
        <SuiBox display="flex" columnGap="8px">
          <IconButton
            onClick={() => {
              if (!isFetching) {
                refetch();
              }
            }}
          >
            <SyncIcon
              sx={{
                animation: isFetching ? `${spin} 1s infinite ease` : undefined,
              }}
            />
          </IconButton>
          <IconButton
            onClick={() => {
              setIsOpenAddICCard(true);
            }}
          >
            <AddIcon />
          </IconButton>
        </SuiBox>
      }
      {...modalProps}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          pt: 2,
          pb: 4,
          borderTop: '1px dashed rgba(0, 0, 0, 0.12)',
        }}
      >
        {listICCard.map((item) => {
          return (
            <LockICCardItem
              item={item}
              key={item.cardId}
              onDeleteICCard={handleDeleteICCard}
              onOpenEditICCard={handleOpenEditICCard}
            />
          );
        })}
        {isOpenUpdateICCard && iCIdentityCard && (
          <LockICCardFormUpdate
            onEditICCard={handleUpdateICCard}
            cardData={iCIdentityCard}
            onClose={handleCloseEditICCardModal}
            loading={isEditICCard}
          />
        )}
        {isOpenAddICCard && (
          <LockICCardFormCreate
            lockId={lockId}
            onAddICCard={handleAddICCard}
            loading={isAddICCard}
            deviceName={lockName}
            onClose={() => setIsOpenAddICCard(false)}
          />
        )}
        {isFetching && <Loader />}
        {hasNextPage && (
          <Waypoint
            onEnter={() => {
              if (!isFetching && !isFetchingNextPage) {
                fetchNextPage();
              }
            }}
          />
        )}
      </Box>
    </SuiModal>
  );
}

export default LockICCardList;
