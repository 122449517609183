import EditIcon from '@mui/icons-material/Edit';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import PinDropIcon from '@mui/icons-material/PinDrop';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Unstable_Grid2 as Grid2,
  InputAdornment,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { calendarAtom } from 'components/Calendar/atoms';
import FormikInput from 'components/Formik/FormikInput';
import NumericFormatInput from 'components/NumericFormatInput';
import SuiBox from 'components/SuiBox';
import SuiModal from 'components/SuiModal';
import SuiTypography from 'components/SuiTypography';
import { Formik, FormikProps } from 'formik';
import { useSetAtom } from 'jotai/react';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Booking, CreateBooking } from 'shared/models/booking.model';
import * as yup from 'yup';

import { useGetBillByBooking } from 'apis-query/bill.query';
import {
  BOOING_QUERY_KEY,
  useGetBooking,
  useUpdateBooking,
} from 'apis-query/booking.query';
import { createBooking } from 'apis/booking.api';
import { getCustomerByPhone } from 'apis/customer.api';
import FormikCheckbox from 'components/Formik/FormikCheckbox';
import { Loader } from 'components/LoadingIndicator';
import { ApiError } from 'interfaces/api-response.interface';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { debounce } from 'lodash-es';
import { queryClient } from 'queryClient';
import { NumberFormatValues, numericFormatter } from 'react-number-format';
import { BookingStatus, OrderStatus } from 'shared/enums';
import { useLazyQuery } from 'shared/hooks/useLazyQuery';
import { Customer } from 'shared/models/customer.model';
import { useMutation } from 'shared/packages/myla-query';
import { ALLOW_FOR_STATUSES, WAITING_FOR_STATUSES } from '../../constants';
import useCalculatorBill from '../../hooks/useCalculatorBill';
import BookingBill from '../BookingBill';
import FormErrors from '../FormErrors';
import BookingStatusTag from './BookingStatusTag';
import FloatFormatInput from './FloatFormatInput';
import ListChildRoom from './ListChildRoom';
import displayPeriodDay from './helpers';
import { BookingFormState } from './types';

const bookingFormValidationSchema = yup.object().shape({
  customerName: yup.string().required('Tên khách hàng không được để trống'),
  customerPhone: yup
    .string()
    .required('Số điện thoại không được để trống')
    .test({
      message: 'Số điện thoại không hợp lệ',
      test: async (value, ctx) => {
        try {
          if (isValidPhoneNumber(value || '', 'VN')) return true;
          if (/^\+[0-9]{9,}$/.test(value || '')) {
            return true;
          }
        } catch (err) {
          return ctx.createError({
            message: 'Số điện thoại không hợp lệ',
          });
        }

        return ctx.createError({
          message: 'Số điện thoại không hợp lệ',
        });
      },
    }),
  deposit: yup.number().required('Tiền cọc không được để trống'),
  numberOfGuests: yup.number().required('Số khách không được để trống'),
});

export interface BookingFormProps {
  booking?: BookingFormState;
  open: boolean;
  keepMounted?: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  toggleBookingForm?: (open: boolean, bookingData?: BookingFormState) => void;
}

interface BookingFormik {
  _id?: string;
  customerName: string;
  customerPhone: string;
  notes: string;
  numberOfGuests: number;
  dateRange: string;
  deposit: number;
  deposited?: string[];
}
const DEFAULT_DEPOSITED = [
  {
    text: '',
    value: 'deposited',
  },
];
const CUSTOMER_QUERY_GET_BY_PHONE = 'CUSTOMER_QUERY_GET_BY_PHONE';

function InfoItem({ icon, content }: { icon: ReactNode; content: ReactNode }) {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        gap: 2,
        lineHeight: 1,
        flexWrap: 'nowrap',
      }}
    >
      {icon}
      <SuiTypography variant="h5" fontWeight={400} fontSize="1rem">
        {content}
      </SuiTypography>
    </Box>
  );
}

export default function BookingForm({
  booking: { roomPrices, ...initialBooking } = {},
  open = false,
  onClose,
  onSuccess,
  toggleBookingForm,
  keepMounted = false,
}: BookingFormProps) {
  const theme = useTheme();
  const {
    data: bookingBill,
    remove: removeBillCache,
    isFetching: isFetchingBookingBill,
    isRefetching: isRefetchingBookingBill,
  } = useGetBillByBooking(initialBooking?._id || '', {
    populate: 'order,order.childRoom,order.childRoom.room',
  });

  const {
    data,
    isFetching: isFetchingBooking,
    isRefetching: isRefetchingBooking,
  } = useGetBooking(initialBooking?._id || '', {
    populate: 'distributor,customer,unit,childRooms,childRooms.room',
  });

  const bookingData = useMemo(
    () => (data?._id ? data : initialBooking),
    [data, initialBooking]
  );

  const bookingBillValid = useMemo(() => {
    return (
      bookingBill?.filter(
        (bill) => bill.order?.status !== OrderStatus.CANCELLED
      ) || []
    );
  }, [bookingBill]);

  const billCalculator = useCalculatorBill({
    bills: bookingBillValid,
    booking: bookingData,
    roomPrices,
  });

  const formikRef = useRef<FormikProps<BookingFormik> | null>(null);
  const setCalendarState = useSetAtom(calendarAtom);

  const [isOpenBookingBill, setIsOpenBookingBill] = useState(false);

  const {
    mutate: createBookingMutation,
    isLoading: isLoadingCreate,
    error: createBookingError,
  } = useMutation<Booking, ApiError, CreateBooking>({
    mutationFn: (values) => createBooking(values),
    onSuccess: async () => {
      setCalendarState({ selectedRows: [] });
      formikRef.current?.resetForm();
      onSuccess?.();
      onClose();
    },
    notify: {
      success: (createBookingSuccessData) => ({
        message: 'Tạo booking thành công!',
        actions: [
          {
            key: 'viewBooking',
            icon: VisibilityIcon,
            onClick: () =>
              toggleBookingForm?.(true, { _id: createBookingSuccessData._id }),
          },
        ],
      }),
      error: 'Tạo booking thất bại, vui lòng thử lại sau!',
    },
  });

  const {
    mutateAsync: updateBookingMutation,
    isLoading: isLoadingUpdate,
    error: updateBookingError,
  } = useUpdateBooking({
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        BOOING_QUERY_KEY.GET_BOOKING,
        initialBooking?._id || '',
      ]);
      onSuccess?.();
      if (bookingData?.status !== BookingStatus.LOCKED) {
        onClose();
      }
    },
    notify: {
      success: 'Cập nhật booking thành công!',
      error: 'Cập nhật booking thất bại, vui lòng thử lại sau!',
    },
  });

  const {
    lazyQuery: searchCustomerByPhone,
    isFetching: isSearchingCustomerByPhone,
  } = useLazyQuery<Customer, string>({
    fetcher: (phone) => getCustomerByPhone(phone || ''),
    queryKey: [CUSTOMER_QUERY_GET_BY_PHONE],
    onSuccess: (customerValue) => {
      formikRef.current?.setFieldValue(
        'customerName',
        customerValue?.name || ''
      );
    },
    keepPreviousData: true,
    retry: false,
  });

  const handleCreateBooking = useCallback(
    async (values: BookingFormik) => {
      if (
        !bookingData.unit?._id ||
        !bookingData.expectCheckInDate ||
        !bookingData.expectCheckOutDate ||
        !Array.isArray(bookingData.childRooms)
      ) {
        return;
      }

      createBookingMutation({
        ...values,
        unitId: bookingData?.unit._id,
        checkInDate: bookingData.expectCheckInDate,
        checkOutDate: bookingData.expectCheckOutDate,
        childRoomsIds: bookingData.childRooms.map((childRoom) => childRoom._id),
        deposit: values.deposit,
        isDeposited: values?.deposited?.includes('deposited'),
      });
    },
    [bookingData, createBookingMutation]
  );

  const updateBooking = useCallback(
    async (values: BookingFormik) => {
      await updateBookingMutation({
        _id: bookingData._id || '',
        notes: values.notes,
        numberOfGuests: values.numberOfGuests,
        deposit: values.deposit,
        discount: bookingData.discount || 0,
        serviceCharge: bookingData.serviceCharge || [],
      });
    },
    [
      bookingData._id,
      bookingData.discount,
      bookingData.serviceCharge,
      updateBookingMutation,
    ]
  );

  const handleEditBooking = useCallback(() => {
    if (!bookingData?._id) return;

    setIsOpenBookingBill(true);
  }, [bookingData]);

  const onSubmit = useCallback(
    async (values: BookingFormik) => {
      if (!bookingData?._id) {
        await handleCreateBooking(values);
      } else {
        await updateBooking({
          ...values,
          _id: bookingData._id,
        });
      }
    },

    [bookingData._id, handleCreateBooking, updateBooking]
  );

  const initFormValues = useMemo(
    () => ({
      customerName: bookingData?.customer?.name || '',
      customerPhone: bookingData?.customer?.phone || '',
      numberOfGuests: bookingData?.numberOfGuests || 0,
      dateRange: displayPeriodDay(
        bookingData?.expectCheckInDate,
        bookingData?.expectCheckOutDate
      ),
      deposit: bookingData?.deposit || 0,
      notes: bookingData?.notes || '',
      deposited: bookingData?.isDeposited ? ['deposited'] : [],
    }),
    [bookingData]
  );

  const suiModalConfig = useMemo(() => {
    return {
      unitName: bookingData?.unit?.name || '',
      distributorName: bookingData?.distributor?.name || '',
      title: `${bookingData?._id ? 'Cập nhật' : 'Tạo'} booking`,
      confirmText: `${bookingData?._id ? 'Cập nhật' : 'Tạo đơn'}`,
      cancelText: 'ĐÓNG',
    };
  }, [bookingData]);

  const onSearchUserChange = useMemo(
    () =>
      debounce((value) => {
        if (formikRef.current?.getFieldMeta('customerPhone').error) {
          return;
        }
        searchCustomerByPhone(value);
      }, 350),
    [searchCustomerByPhone]
  );

  useEffect(() => removeBillCache, []);

  const formError = createBookingError || updateBookingError;

  const isLoadingPage =
    isFetchingBooking ||
    isFetchingBookingBill ||
    isRefetchingBookingBill ||
    isRefetchingBooking;

  return (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initFormValues}
      innerRef={formikRef}
      validationSchema={bookingFormValidationSchema}
    >
      <SuiModal
        keepMounted={keepMounted}
        showCloseIcon={false}
        loading={isLoadingCreate || isLoadingUpdate}
        confirmDisabled={isLoadingPage}
        backText="Về trang trước"
        open={open}
        onClose={onClose}
        sx={{
          h4: {
            width: '100%',
          },
        }}
        confirmText={suiModalConfig.confirmText}
        cancelText={suiModalConfig.cancelText}
        onConfirm={() => formikRef.current?.submitForm()}
        title={
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography
              sx={{
                fontSize: {
                  xs: '1.3rem',
                  sm: '1.6rem',
                  md: '2rem',
                },
              }}
              fontWeight="bold"
            >
              {suiModalConfig.title}
            </Typography>
            {bookingData?.status && (
              <BookingStatusTag
                bookingId={bookingData._id}
                status={bookingData?.status}
                onSubmitForm={async () => formikRef.current?.submitForm()}
              />
            )}
          </SuiBox>
        }
        desktopHeight="80dvh"
        desktopWidth={theme.spacing(100)}
      >
        <>
          {bookingData._id && isLoadingPage ? (
            <Loader />
          ) : (
            <SuiBox>
              <Box
                mt={1}
                mb={1.5}
                display="flex"
                columnGap={3}
                rowGap={2}
                flexWrap="wrap"
              >
                <InfoItem
                  icon={<HomeWorkIcon />}
                  content={suiModalConfig.distributorName}
                />
                <InfoItem
                  icon={<PinDropIcon />}
                  content={suiModalConfig.unitName}
                />
              </Box>
              <SuiBox
                sx={{
                  borderTop: '1px dashed #BDBDBD',
                }}
                pt={1.5}
              >
                <Grid2
                  container
                  mb={3}
                  width="100%"
                  rowSpacing={{ xs: 2 }}
                  columnSpacing={{ xs: 1, md: 2 }}
                >
                  <Grid2 xs={12}>
                    <FormErrors
                      title={`${
                        bookingData?._id ? 'Cập nhật' : 'Tạo'
                      } booking không thành công`}
                      error={formError}
                    />
                  </Grid2>
                  <Grid2 md={4.2} xs={12}>
                    <FormikInput
                      name="customerName"
                      placeholder="Tên khách hàng"
                      label="Họ tên khách"
                      loading={isSearchingCustomerByPhone}
                      withIcon={{
                        icon: <PersonOutlineIcon />,
                        direction: 'left',
                      }}
                      readOnly={!!bookingData?._id}
                    />
                  </Grid2>
                  <Grid2 md={4.2} xs={12}>
                    <FormikInput
                      name="customerPhone"
                      placeholder="Số điện thoại khách hàng"
                      label="Số điện thoại"
                      type="tel"
                      withIcon={{
                        icon: <PhoneIphoneIcon />,
                        direction: 'left',
                      }}
                      readOnly={!!bookingData?._id}
                      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
                        const phone = e.currentTarget.value;
                        onSearchUserChange(phone);
                      }}
                    />
                  </Grid2>
                  <Grid2 md={3.6} xs={12}>
                    <FormikInput
                      name="numberOfGuests"
                      placeholder="Số khách"
                      inputComponent={NumericFormatInput as any}
                      inputProps={{
                        inputMode: 'numeric',
                      }}
                    />
                  </Grid2>
                  <Grid2 xs={12} md={6}>
                    <FormikInput
                      hideSuccessIndicator
                      name="dateRange"
                      label="Thời gian lưu trú"
                      disabled
                      readOnly
                    />
                  </Grid2>
                  <Grid2 xs={12} md={6}>
                    <FormikInput
                      name="deposit"
                      placeholder={
                        !bookingData?.status ||
                        WAITING_FOR_STATUSES.includes(bookingData?.status)
                          ? 'Số tiền phải cọc'
                          : 'Số tiền đã thanh toán'
                      }
                      inputProps={{
                        suffix: ` / ${numericFormatter(
                          (billCalculator.totalAfterDiscount || 0).toString(),
                          {
                            thousandSeparator: true,
                          }
                        )}`,
                        isAllowed: ({ floatValue }: NumberFormatValues) => {
                          return (
                            floatValue === undefined ||
                            floatValue <= billCalculator.totalAfterDiscount
                          );
                        },
                        inputMode: 'decimal',
                        allowNegative: false,
                        readOnly:
                          (!bookingData?.status ||
                            !ALLOW_FOR_STATUSES.includes(
                              bookingData?.status
                            )) &&
                          !!bookingData?._id,
                      }}
                      InputProps={{
                        inputComponent: FloatFormatInput as any,
                        endAdornment: (
                          <InputAdornment position="end">
                            {bookingData?._id ? (
                              <EditIcon
                                onClick={handleEditBooking}
                                sx={{ cursor: 'pointer' }}
                              />
                            ) : (
                              <Tooltip
                                arrow
                                title="Đã nhận cọc"
                                placement="top"
                              >
                                <div>
                                  <FormikCheckbox
                                    name="deposited"
                                    sx={{
                                      label: {
                                        gap: 0,
                                      },
                                      '& .MuiCheckbox-root': {
                                        p: 0,
                                      },
                                    }}
                                    options={DEFAULT_DEPOSITED}
                                  />
                                </div>
                              </Tooltip>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid2>
                  <Grid2 xs={12}>
                    <FormikInput
                      name="notes"
                      multiline
                      placeholder="Ghi chú"
                      rows={2}
                    />
                  </Grid2>
                </Grid2>
              </SuiBox>
              <ListChildRoom
                childRooms={bookingData?.childRooms || []}
                title="Thông tin phòng"
                bills={bookingBill}
                isEdit={!!bookingData?._id}
              />
            </SuiBox>
          )}
          {isOpenBookingBill && (
            <BookingBill
              booking={bookingData}
              onClose={() => setIsOpenBookingBill(false)}
              open={isOpenBookingBill}
            />
          )}
        </>
      </SuiModal>
    </Formik>
  );
}
