import { Box } from '@mui/material';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { ChangeEventHandler, ReactNode, useCallback, useState } from 'react';
import { DateRangeProps } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-spring-bottom-sheet/dist/style.css';
import { isValid } from 'date-fns';
import { SuiInputProps } from '../SuiInput';

interface CustomDateTimePickerProps
  extends Omit<DateTimePickerProps<Date>, 'onChange'> {
  label?: ReactNode | string;
  name?: string;
  value: Date;
  format?: string;
  onBlur?: ChangeEventHandler;
  onChange: (value: Date | null) => void;
  SuiInputProps?: Partial<SuiInputProps>;
}

export default function CustomDateTimePicker({
  name,
  value,
  onBlur,
  onChange,
  label,
  format: dateFormat = 'dd/MM/yyyy hh:mm aa',
  SuiInputProps: suiInputProps,
  ...rest
}: CustomDateTimePickerProps) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(value);

  const onSelectionChange = useCallback(
    (selections: Date | null) => {
      if (
        !selections ||
        (typeof selections === 'string' && selections === 'Invalid Date') ||
        !isValid(selections)
      ) {
        onChange(null);
        setSelectedDate(null);
      }
      onChange(selections);
      setSelectedDate(selections);
    },
    [onChange]
  );

  return (
    <Box
      sx={{
        '.MuiTextField-root': {
          width: '100%',
        },
      }}
    >
      <DateTimePicker
        {...rest}
        value={selectedDate}
        onChange={onSelectionChange}
        closeOnSelect
        label={label}
        format={dateFormat}
        slotProps={{
          desktopPaper: {
            sx: {
              '.MuiMultiSectionDigitalClock-root': {
                width: 'fit-content',
              },
              '.MuiMultiSectionDigitalClock-root li': {
                minWidth: 'auto',
              },
              ' hr': {
                margin: '0!important',
              },
            },
          },
          textField: {
            ...suiInputProps,
          },
        }}
      />
    </Box>
  );
}
