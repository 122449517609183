import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteRenderInputParams,
  CircularProgress,
} from '@mui/material';
import SuiInput, { SuiInputProps } from 'components/SuiInput';
import { ReactNode, useCallback } from 'react';

export interface BaseOption {
  value: string | number;
  label: string;
  group?: string;
}

export interface SuiSelectProps<
  Option extends BaseOption,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = true,
  FreeSolo extends boolean | undefined = false
> extends Omit<
    AutocompleteProps<Option, Multiple, DisableClearable, FreeSolo>,
    'styles' | 'renderInput'
  > {
  label?: ReactNode | string;
  success?: boolean;
  readOnly?: boolean;
  SuiInputProps?: Partial<SuiInputProps>;
}

function SuiSelect<
  Option extends BaseOption = BaseOption,
  Multiple extends boolean = false,
  DisableClearable extends boolean | undefined = true,
  FreeSolo extends boolean | undefined = false
>({
  options,
  label,
  multiple,
  SuiInputProps: suiInputProps,
  loading = false,
  readOnly = false,
  disableClearable = true as DisableClearable,
  ...rest
}: SuiSelectProps<Option, Multiple, DisableClearable, FreeSolo>) {
  const defaultRenderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <SuiInput
        {...suiInputProps}
        {...params}
        label={label}
        variant="outlined"
        inputProps={{
          ...suiInputProps?.inputProps,
          ...params.inputProps,
        }}
        InputLabelProps={{
          ...suiInputProps?.InputLabelProps,
          ...params.InputLabelProps,
        }}
        InputProps={{
          ...suiInputProps?.InputProps,
          ...params.InputProps,
          endAdornment: (
            <>
              {loading ? <CircularProgress color="info" size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    ),
    [label, loading, suiInputProps]
  );

  return (
    <Autocomplete<Option, Multiple, DisableClearable, FreeSolo>
      selectOnFocus
      autoComplete
      filterSelectedOptions
      multiple={multiple}
      options={options}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      loading={loading}
      disableClearable={disableClearable}
      renderInput={defaultRenderInput}
      popupIcon={readOnly ? null : <KeyboardArrowDownIcon fontSize="small" />}
      readOnly={readOnly}
      disableCloseOnSelect={multiple}
      renderOption={(props, option) => (
        <li {...props} key={option.value}>
          {option.label}
        </li>
      )}
      {...rest}
    />
  );
}

export default SuiSelect;
