import BadgeIcon from '@mui/icons-material/Badge';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import LockClockIcon from '@mui/icons-material/LockClock';
import PinIcon from '@mui/icons-material/Pin';
import RemoveIcon from '@mui/icons-material/Remove';
import SellIcon from '@mui/icons-material/Sell';
import SettingsIcon from '@mui/icons-material/Settings';

import { Box, Card, IconButton, Typography } from '@mui/material';
import SuiBox from 'components/SuiBox';
import { format, isValid } from 'date-fns';
import { ICIdentityCard } from 'shared/models';

function LockICCardItem({
  item,
  onOpenEditICCard,
  onDeleteICCard,
}: {
  item: ICIdentityCard;
  onOpenEditICCard: (item: ICIdentityCard) => void;
  onDeleteICCard: (item: ICIdentityCard) => void;
}) {
  const {
    cardId,
    cardName,
    cardNumber,
    cardType,
    startDate,
    endDate,
    createDate,
  } = item;

  return (
    <Card
      key={cardId}
      sx={{ p: 3, alignItems: 'center', flexDirection: 'row' }}
    >
      <Box
        flex={1}
        rowGap={1}
        display="flex"
        flexDirection="column"
        sx={{
          color: 'text.secondary',
        }}
      >
        <Typography display="flex" alignItems="center">
          <LockClockIcon
            titleAccess="Thời gian hiệu lực"
            fontSize="small"
            sx={{ mr: 1.5 }}
          />
          {startDate === 0 && endDate === 0 ? (
            <Typography variant="h6" component="span">
              Thời gian vĩnh viễn
            </Typography>
          ) : (
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle2"
              component="span"
            >
              {isValid(new Date(startDate))
                ? format(new Date(startDate), 'HH:ss dd/MM/yyyy')
                : 'N/A'}
              &nbsp; <RemoveIcon fontSize="small" /> &nbsp;
              {isValid(new Date(endDate))
                ? format(new Date(endDate), 'HH:ss dd/MM/yyyy')
                : 'N/A'}
            </Typography>
          )}
        </Typography>
        <SuiBox display="flex" alignItems="center">
          <BadgeIcon titleAccess="Tên thẻ" fontSize="medium" sx={{ mr: 1.5 }} />
          <Typography variant="subtitle2" component="span">
            {cardName}
          </Typography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <SellIcon titleAccess="Loại thẻ" fontSize="medium" sx={{ mr: 1.5 }} />
          <Typography variant="h6" component="span">
            {cardType === 1 ? 'Normal' : 'Cyclic'}
          </Typography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <HistoryIcon
            titleAccess="Ngày thêm thẻ từ"
            fontSize="medium"
            sx={{ mr: 1.5 }}
          />
          <Typography variant="h6" component="span">
            {isValid(new Date(createDate))
              ? format(new Date(createDate), 'HH:ss dd/MM/yyyy')
              : 'N/A'}
          </Typography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <PinIcon titleAccess="Mã số thẻ" fontSize="medium" sx={{ mr: 1.5 }} />
          <Typography variant="h6" component="span">
            {cardNumber}
          </Typography>
        </SuiBox>
        <SuiBox display="flex" alignItems="center">
          <SettingsIcon fontSize="medium" sx={{ mr: 1.5 }} />
          <IconButton
            onClick={() => onOpenEditICCard(item)}
            size="medium"
            color="success"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => onDeleteICCard(item)}
            size="medium"
            color="error"
          >
            <DeleteIcon />
          </IconButton>
        </SuiBox>
      </Box>
    </Card>
  );
}

export default LockICCardItem;
