import { Theme } from '@mui/material';
import { Interpolation } from '@mui/styled-engine';

const globalStyles: Interpolation<Theme> = {
  html: {
    scrollbarGutter: 'stable both-edges',
  },

  'body > .Sui-Modal': {
    opacity: 0,
  },

  ':nth-last-child(1 of div.Sui-Modal)': {
    opacity: 1,
  },

  '.Toastify__toast': {
    borderRadius: '8px !important',
  },

  '.Toastify__toast-theme--light > .Toastify__toast-body::before': {
    content: "''",
    width: '4px',
    alignSelf: 'stretch',
    marginRight: '12px',
    borderRadius: '4px',
  },
  '.Toastify__toast-icon': {
    marginRight: '12px',
  },
  '.Toastify__toast--info > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-info)',
  },
  '.Toastify__toast--success > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-success)',
  },
  '.Toastify__toast--warning > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-warning)',
  },
  '.Toastify__toast--error > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-error)',
  },
  '.Toastify__toast--spinner > .Toastify__toast-body::before': {
    backgroundColor: 'var(--toastify-color-spinner)',
  },
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: '#e9ecef',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#adb5bd',
    borderRadius: '8px',
  },
  '.loading > .MuiPaper-root': {
    position: 'relative',
  },
  '.loading > .MuiPaper-root::before': {
    content: "''",
    opacity: 1,
    position: 'absolute',
    borderRadius: 'inherit',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: 9999,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    visibility: 'visible',
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
  },
  '.loading > .MuiPaper-root:after': {
    content: "''",
    borderTopColor: '#3366ff',
    borderRightColor: 'transparent',
    borderBottomColor: '#3366ff',
    borderLeftColor: '#3366ff',
    height: '1em',
    width: '1em',
    borderWidth: '0.125em',
    animation: 'spin 0.8s linear infinite',
    borderRadius: '50%',
    borderStyle: 'solid',
    zIndex: 10000,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '40%': {
      transform: 'rotate(230deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },
};

export default globalStyles;
