import Grid2 from '@mui/material/Unstable_Grid2';
import FormikDateTimePicker from 'components/Formik/FormikDateTimePicker';
import FormikInput from 'components/Formik/FormikInput';
import SuiBox from 'components/SuiBox';
import SuiModal from 'components/SuiModal';
import { Formik, FormikProps } from 'formik';
import { useCallback, useMemo, useRef } from 'react';
import { ICIdentityCard } from 'shared/models';

export interface ICIdentityCardFormik {
  lockId: string;
  cardId: string;
  cardName: string;
  startDate: Date;
  endDate: Date;
}
function LockICCardFormUpdate({
  cardData,
  onEditICCard,
  onClose,
  loading,
}: {
  cardData: ICIdentityCard;
  onEditICCard: (values: ICIdentityCardFormik) => void;
  onClose: () => void;
  loading: boolean;
}) {
  const formikRef = useRef<FormikProps<ICIdentityCardFormik> | null>(null);
  const initFormValues = useMemo(() => {
    return {
      lockId: cardData.lockId.toString(),
      cardId: cardData.cardId.toString(),
      cardName: cardData.cardName,
      startDate: new Date(cardData.startDate),
      endDate: new Date(cardData.endDate),
    };
  }, [
    cardData.cardId,
    cardData.cardName,
    cardData.endDate,
    cardData.lockId,
    cardData.startDate,
  ]);

  const handleUpdateICCard = useCallback(
    (values: ICIdentityCardFormik) => {
      onEditICCard(values);
    },
    [onEditICCard]
  );

  const handleOnclose = useCallback(
    (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={handleUpdateICCard}
      initialValues={initFormValues}
      innerRef={formikRef}
    >
      <SuiModal
        showCloseIcon={false}
        backText="Về trang trước"
        open
        confirmText="Cập nhật"
        cancelText="Thoát"
        onConfirm={() => formikRef.current?.submitForm()}
        title="Cập nhật thẻ từ"
        loadingOverlay={loading}
        onClose={handleOnclose}
        onCancel={onClose}
      >
        <SuiBox>
          <SuiBox
            sx={{
              borderTop: '1px dashed #BDBDBD',
            }}
            pt={1.5}
          >
            <Grid2
              container
              mb={3}
              width="100%"
              rowSpacing={{ xs: 2 }}
              columnSpacing={{ xs: 1, md: 2 }}
            >
              <Grid2 xs={12} md={12}>
                <FormikInput
                  name="cardName"
                  placeholder="Nhập tên thẻ từ"
                  label="Tên thẻ từ"
                />
              </Grid2>

              <Grid2 xs={12} md={12}>
                <FormikDateTimePicker
                  name="startDate"
                  label="Chọn ngày bắt đầu"
                />
              </Grid2>
              <Grid2 xs={12} md={12}>
                <FormikDateTimePicker
                  name="endDate"
                  label="Chọn ngày hết hạn"
                />
              </Grid2>
            </Grid2>
          </SuiBox>
        </SuiBox>
      </SuiModal>
    </Formik>
  );
}

export default LockICCardFormUpdate;
