import { Box, useMediaQuery, useTheme } from '@mui/material';
import { format } from 'date-fns';
import { size } from 'lodash-es';
import {
  ChangeEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DateRange, DateRangeProps, Range } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-spring-bottom-sheet/dist/style.css';
import ResponsivePopover from '../ResponsivePopover';
import SuiInput, { SuiInputProps } from '../SuiInput';

export interface DatePickerRange extends Range {
  key: string;
}

interface CustomDateRangePickerProps
  extends Pick<DateRangeProps, 'disabledDates' | 'minDate' | 'maxDate'> {
  label?: ReactNode | string;
  name?: string;
  value: DatePickerRange[];
  error?: boolean;
  format?: string;
  success?: boolean;
  placeholder?: string;
  onBlur?: ChangeEventHandler;
  onChange: (value: DatePickerRange[]) => void;
  SuiInputProps?: Partial<SuiInputProps>;
}

export default function CustomDateRangePicker({
  name,
  value,
  onBlur,
  onChange,
  placeholder,
  label,
  error = false,
  success = false,
  format: dateFormat = 'dd/MM/yy',
  SuiInputProps: suiInputProps,
  ...rest
}: CustomDateRangePickerProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const shouldDismissCalendarRef = useRef<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<DatePickerRange[]>(value);
  const [calendarAnchorEle, setCalendarAnchorEle] =
    useState<HTMLDivElement | null>(null);
  const dismissCalendar = useCallback(() => setCalendarAnchorEle(null), []);

  const onSelectionChange = useCallback(
    (selections: Record<string, Range>) => {
      const newSelectedDate = selectedDate.reduce(
        (acc: DatePickerRange[], range) => {
          const selectedValue = selections[range.key];
          return [...acc, { ...selectedValue, key: range.key } || range];
        },
        []
      );
      setSelectedDate(newSelectedDate);

      if (shouldDismissCalendarRef.current) {
        onChange(newSelectedDate);
        dismissCalendar();
        shouldDismissCalendarRef.current = false;
      } else if (size(selectedDate) === 1) {
        shouldDismissCalendarRef.current = true;
      }
    },
    [dismissCalendar, onChange, selectedDate]
  );

  const displayValue = useMemo(
    () =>
      selectedDate
        .reduce((acc: string[], { startDate, endDate }) => {
          if (!startDate || !endDate) {
            return acc;
          }

          return [
            ...acc,
            `${format(startDate, dateFormat)} - ${format(endDate, dateFormat)}`,
          ];
        }, [])
        .join(', '),
    [dateFormat, selectedDate]
  );

  const calendar = useMemo(
    () =>
      !!calendarAnchorEle && (
        <Box
          sx={{
            '& .rdrCalendarWrapper.rdrDateRangeWrapper': {
              width: '100%',
              '& .rdrMonth': {
                width: '100%',
              },
            },
          }}
        >
          <DateRange
            {...rest}
            showMonthArrow
            showMonthAndYearPickers
            months={2}
            ranges={selectedDate}
            showDateDisplay={false}
            direction={isMobile ? 'vertical' : 'horizontal'}
            onChange={onSelectionChange}
            rangeColors={[theme.palette.info.main]}
            scroll={{ enabled: isMobile }}
          />
        </Box>
      ),
    [
      calendarAnchorEle,
      isMobile,
      onSelectionChange,
      rest,
      theme.palette.info.main,
      selectedDate,
    ]
  );

  return (
    <Box>
      <SuiInput
        readOnly
        name={name}
        label={label}
        error={error}
        onBlur={onBlur}
        success={success}
        value={displayValue}
        placeholder={placeholder}
        onMouseDown={(e) => setCalendarAnchorEle(e.currentTarget)}
        {...suiInputProps}
      />
      <ResponsivePopover
        anchorElement={calendarAnchorEle}
        onDismiss={() => setCalendarAnchorEle(null)}
      >
        {calendar}
      </ResponsivePopover>
    </Box>
  );
}
