import { Box, useTheme } from '@mui/material';
import { ComponentProps } from 'react';
import HashLoader from 'react-spinners/HashLoader';

export default function Spinner(props: ComponentProps<typeof HashLoader>) {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" justifyContent="center" my={2}>
      <HashLoader color={theme.palette.myla.main} {...props} />
    </Box>
  );
}
