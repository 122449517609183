import { UnitType } from './models/unit.model';

export const API_PATHS = {
  USERS: '/users',
  CUSTOMERS: '/customers',
  DISTRIBUTOR: {
    LIST: '/distributors',
    CREATE: '/distributors',
    GET: '/distributors',
    UPDATE: '/distributors',
    UPDATE_LOGO: '/distributors/logo',
    DELETE: '/distributors',
    ALL: '/distributors/get-all',
    WITH_GROUP: '/distributors/selling-groups',
  },
  UNIT: {
    GET: '/units',
    UPDATE: '/units',
    DELETE: '/units/:unitId/images',
    CREATE: '/units',
    LIST: '/units/distributor',
    UPDATE_IMAGE: '/units/:unitId/image',
    UPDATE_THUMBNAIL: '/units/:unitId/thumbnail',
    UPDATE_IMAGES: '/units/:unitId/images',
  },
  SELLING_KEY: {
    GET_ALL: '/selling-keys',
  },
  POLICY_KEY: {
    GET_ALL: '/policies',
  },
  ASSET: {
    REPLACE: '/assets',
    DELETE: '/assets',
  },
  BOOKING: {
    GET: '/bookings/:id',
    CREATE: '/bookings',
    LIST: '/bookings',
    UPDATE: '/bookings/:id/update-info',
    CONFIRM: '/bookings/:id/confirm',
    CHECKIN: '/bookings/:id/check-in',
    CHECKOUT: '/bookings/:id/check-out',
    COMPLETE: '/bookings/:id/complete',
    CANCEL: '/bookings/:id/cancel',
    GET_TOTAL_PRICE: '/bookings/:id/total-price',
    GET_LIST_CHILDROOM_CAN_REPLACE:
      '/bookings/:id/child-rooms-available-in-order',
    REPLACE_CHILDROOM_IN_ORDER: '/bookings/:id/replace-child-room-in-order',
  },
  ORDER: {
    GET: '/orders/:id',
    UPDATE_INFO: '/orders/:id/update-info',
    GET_LIST: '/orders',
  },
  BILL: {
    GET_BY_ORDER_ID: '/bills/order/:orderId',
    UPDATE_INFO: '/bills/:id/update-info',
    BOOKING: '/bills/booking/:bookingId',
    DOWNLOAD: '/export-data/report-sales',
  },
  PRICE: {
    GET_TEMP_PRICE_BILL: '/prices/temp-price-bill',
    GET_PRICE_ROOMS: '/prices/get-price-rooms',
  },
  PRICES: 'prices',
  DEVICE_TOKEN: '/device-tokens',
  NOTIFICATION: {
    UPDATE_SEEN: 'notifications/seen',
    ARCHIVE: 'notifications/archive',
  },
  USER_DISITRIBUTOR: {
    GET_USER: '/user-distributor/users',
    GET_DISTRIBUTOR: '/user-distributor/distributors',
    CREATE: '/user-distributor',
    UPDATE: '/user-distributor',
  },
  SELLING_GROUPS: '/selling-groups',
  TTLOCK: {
    LIST_PASSCODE: '/user-devices/lock/listKeyboardPwd',
    LIST_DEVICE_AVAILABLE: '/user-devices/list-device-available',
    LIST_DEVICE: '/user-devices/list-device',
    DELETE_PASSCODE: '/ttlock-action/keyboardPwd/delete',
    ADD_CARD_FROM_ORDER: '/user-devices/add-card-from-order',
    EDIT_PASSCODE: '/ttlock-action/keyboardPwd/change',
  },
  IC_CARD: {
    LIST: '/ttlock-action/identityCard/list',
    ADD: '/ttlock-action/identityCard/addForReversedCardNumber',
    DELETE: '/ttlock-action/identityCard/delete',
    CHANGE_PERIOD: '/ttlock-action/identityCard/changePeriod',
    RENAME_NAME: '/ttlock-action/identityCard/rename',
  },
} as const;

export const DEFAULT_ROUTE = '/dashboards';

export const ROUTE_PATHS = {
  ACCOUNT: {
    SETTINGS: '/account/settings',
  },
} as const;

export const enum NotificationType {
  BOOKING_CHANGE_STATUS = 'booking_change_status',
  BOOKING_LOCKED = 'booking_locked',
  ORDER_CHECK_IN = 'order_check_in',
  ORDER_CHECK_OUT = 'order_check_out',
  ORDER_CANCEL = 'order_check_cancel',
}

export const UNIT_TYPE_NAME_MAPPER: Record<UnitType, string> = {
  [UnitType.APARTMENT]: 'Apartment',
  [UnitType.HOME_STAY]: 'Homestay',
  [UnitType.HOTEL]: 'Hotel',
  [UnitType.VILLA]: 'Villa',
};

export const NOTIFICATION_PERMISSION_LOCAL_STORAGE_KEY =
  'myla/notificationPermission';
