import AddCardIcon from '@mui/icons-material/AddCard';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SingleBedIcon from '@mui/icons-material/SingleBed';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Chip,
  ClickAwayListener,
  Grow,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
  Popper,
  Typography,
  styled,
} from '@mui/material';
import { DEVICE_QUERY_KEY } from 'apis-query/device.query';
import { getCardFromReader } from 'apis/deviceServer.api';
import { addCardFromOrder } from 'apis/ttlock.api';
import Spinner from 'components/Spinner';
import SuiBox from 'components/SuiBox';
import SuiModal from 'components/SuiModal';
import SuiTypography from 'components/SuiTypography';
import { QUERY_KEYS } from 'pages/lockManagement/constants';
import { useCallback, useMemo, useRef, useState } from 'react';
import { OrderStatus } from 'shared/enums';
import { useLazyQuery } from 'shared/hooks/useLazyQuery';
import { ChildRoomWithRoomData } from 'shared/models/booking.model';
import { OrderBill } from 'shared/models/orderBill.model';
import { OrderBooking } from 'shared/models/orderBooking.model';
import { useNotifyToast } from 'shared/packages/myla-query/hooks/useNotifyToast';
import { NotifyStatus } from 'shared/packages/myla-query/types';
import theme from 'shared/theme';
import pxToRem from 'shared/theme/functions/pxToRem';
import { getEllipsisStyles } from 'shared/utils/styles';
import { currencyFormat } from 'utils/currencyFormat';
import { BILL_QUERY_KEY } from '../../../../../apis-query/bill.query';
import {
  BOOING_QUERY_KEY,
  useGetListChildRoomCanReplace,
  useReplaceChildRoomOrder,
} from '../../../../../apis-query/booking.query';
import { Loader } from '../../../../../components/LoadingIndicator';
import SuiButton from '../../../../../components/SuiButton';
import { queryClient } from '../../../../../queryClient';
import { ScreenActiveEnum } from '../../constants';
import OrderBillForm from '../OrderBill';
import OrderForm from '../OrderForm';
import { BOOKING_STATUS_COLORS, BOOKING_STATUS_TEXTS } from './constants';

interface ListChildRoomProps {
  childRooms: ChildRoomWithRoomData[];
  title: string;
  bills?: OrderBill[];
  isEdit?: boolean;
  screenActive?: ScreenActiveEnum;
}

const ChildRoomStyled = styled(Accordion)`
  box-shadow: 4px 4px 16px 0px rgba(12, 74, 157, 0.08);
  border-radius: 0.5rem !important;
  border: 1px solid #f2f2f2;
  &::before {
    display: none;
  }
`;

function ListChildRoom({
  childRooms,
  title,
  bills,
  isEdit = false,
  screenActive,
}: ListChildRoomProps) {
  const [openListChildRoom, setOpenListChildRoom] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderBooking | undefined>(
    undefined
  );

  const [checkingOrder, setCheckingOrder] = useState<OrderBooking | undefined>(
    undefined
  );
  const [childRoomIdSelected, setChildRoomIdSelected] = useState<string>('');
  const [childRoomChangedSelected, setChildRoomChangedSelected] =
    useState<string>('');
  const anchorRef = useRef<HTMLDivElement>(null);

  const { showToast } = useNotifyToast();

  const { isFetching: isRegisteringCardToOrder, lazyQuery: registerCard } =
    useLazyQuery<
      any,
      {
        cardNumber?: string;
        orderId?: string;
      }
    >({
      queryKey: [checkingOrder?._id, QUERY_KEYS.LOCK_ADD_CARD],
      fetcher: (params) => addCardFromOrder(params || {}),
      onSuccess() {
        showToast({
          notifyStatus: NotifyStatus.SUCCESS,
          toastContent: { message: 'Thêm thẻ thành công' },
        });
      },
      onError() {
        showToast({
          notifyStatus: NotifyStatus.ERROR,
          toastContent: { message: 'Thêm thẻ thất bại, vui lòng thử lại' },
        });
      },
    });

  const { isFetching: isReadingCard, lazyQuery: readCard } = useLazyQuery<any>({
    queryKey: [DEVICE_QUERY_KEY.GET_CARD_FROM_READER],
    fetcher: () => getCardFromReader(),
    notify: {
      loading: 'Mời quét thẻ',
    },
    onSuccess(response) {
      if (!response?.rfid || !checkingOrder?._id) {
        return;
      }
      registerCard({ cardNumber: response.rfid, orderId: checkingOrder?._id });
    },
  });

  const listChildRoom = useMemo(() => {
    if (!isEdit) return childRooms;
    return bills?.map((bill) => bill.order?.childRoom).filter(Boolean);
  }, [bills, childRooms, isEdit]);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const renderBillByOrder = (childRoomId: string) => {
    if (!bills || !Array.isArray(bills)) return null;
    const orderBill = bills.find(
      (bill) => String(bill.childRoom).toString() === childRoomId
    ) as OrderBill;

    const totalAmount = () => {
      const totalServiceCharge = orderBill?.serviceCharge?.reduce(
        (acc, item) => {
          return acc + item.value;
        },
        0
      );
      return (orderBill?.totalAmount || 0) + totalServiceCharge;
    };

    // eslint-disable-next-line react/jsx-no-useless-fragment
    if (!orderBill) return <></>;

    return (
      <>
        {orderBill?.items?.map((item) => (
          <ListItem key={item._id}>
            <ListItemText>{item.name}</ListItemText>
            <ListItemText>{currencyFormat(item.value)}</ListItemText>
          </ListItem>
        ))}
        {orderBill?.serviceCharge?.map((item) => (
          <ListItem key={item._id}>
            <ListItemText>{item.name}</ListItemText>
            <ListItemText>{currencyFormat(item.value)}</ListItemText>
          </ListItem>
        ))}
        <ListItem>
          <ListItemText>Tổng tiền</ListItemText>
          <ListItemText>{currencyFormat(totalAmount())}</ListItemText>
        </ListItem>
      </>
    );
  };

  const mapChildRoomAndBill = useMemo(() => {
    const mapChildRoom = new Map<string, OrderBooking>();
    bills?.forEach((bill) => {
      if (bill.childRoom && bill.order)
        mapChildRoom.set(String(bill.childRoom || ''), bill.order);
    });

    return mapChildRoom;
  }, [bills]);

  const getOrder = (childRoomId: string) => {
    return mapChildRoomAndBill.get(childRoomId);
  };

  const { data: listChildRoomCanReplace, isLoading: isLoadingGetChildRoom } =
    useGetListChildRoomCanReplace(
      getOrder(childRoomIdSelected)?._id || '',
      openListChildRoom
    );

  const {
    mutateAsync: replaceChildRoomOrder,
    isLoading: isLoadingReplaceChildRoomOrder,
    error: replaceChildRoomOrderError,
  } = useReplaceChildRoomOrder({
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        BOOING_QUERY_KEY.GET_BOOKING,
        getOrder(childRoomIdSelected)?.booking,
      ]);
      await queryClient.invalidateQueries([
        BILL_QUERY_KEY.GET_BOOKING_BILL,
        getOrder(childRoomIdSelected)?.booking,
      ]);
      setOpenListChildRoom(false);
      setChildRoomIdSelected('');
    },
    notify: {
      success: 'Cập nhật booking thành công!',
      error: 'Cập nhật booking thất bại, vui lòng thử lại sau!',
    },
  });
  const handleChangeChildroom = useCallback(
    (e: React.MouseEvent, childRoomId: string) => {
      e.stopPropagation();
      setOpenListChildRoom(true);
      setChildRoomIdSelected(childRoomId);
    },
    []
  );
  const handleReplaceChildRoom = useCallback(async () => {
    if (!childRoomChangedSelected || isLoadingReplaceChildRoomOrder) return;
    await replaceChildRoomOrder({
      orderId: getOrder(childRoomIdSelected)?._id || '',
      childRoomId: childRoomChangedSelected,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    childRoomChangedSelected,
    childRoomIdSelected,
    isLoadingReplaceChildRoomOrder,
  ]);
  const renderModal = useMemo(() => {
    if (!selectedOrder) return null;
    if (screenActive === ScreenActiveEnum.BOOKING_BILL && selectedOrder?._id) {
      return (
        <OrderBillForm
          orderId={selectedOrder?._id}
          onClose={() => setSelectedOrder(undefined)}
        />
      );
    }
    return (
      <OrderForm
        onClose={() => setSelectedOrder(undefined)}
        orderId={selectedOrder._id}
        onOpenBookingForm={() => setSelectedOrder(undefined)}
      />
    );
  }, [screenActive, selectedOrder]);

  const renderHotelCardBtn = (order?: OrderBooking) => {
    if (!order || order.status !== OrderStatus.CHECKIN) {
      return null;
    }

    return (
      <>
        <SuiModal
          loading={false}
          hideFooter
          showCloseIcon={false}
          open={isReadingCard || isRegisteringCardToOrder}
          title={
            <SuiBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: '1rem',
                    sm: '1.2rem',
                    md: '1.4rem',
                  },
                }}
                fontWeight="bold"
              >
                Đăng kí thẻ
              </Typography>
            </SuiBox>
          }
          desktopHeight="80dvh"
          desktopWidth={theme.spacing(75)}
        >
          <SuiBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            <SuiBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              columnGap={2}
              width="70%"
            >
              <Spinner size={24} />
              <Typography
                sx={{
                  fontSize: {
                    xs: '1rem',
                    sm: '1.2rem',
                    md: '1.4rem',
                  },
                }}
              >
                {isReadingCard && 'Mời quét thẻ...'}
                {isRegisteringCardToOrder && 'Đang thêm thẻ...'}
              </Typography>
            </SuiBox>
          </SuiBox>
        </SuiModal>
        <Box
          sx={{
            borderRadius: '50%',
            border: '1px solid #BDBDBD',
            padding: `0.5rem`,
            gap: theme.spacing(1.5),
          }}
          display="flex"
          alignItems="center"
          onClick={(e) => {
            readCard();
            setCheckingOrder(order);
            e.stopPropagation();
          }}
        >
          <AddCardIcon fontSize="small" />
        </Box>
      </>
    );
  };

  return (
    <SuiBox mb={2}>
      {title && (
        <SuiBox mb={2}>
          <SuiTypography fontSize={pxToRem(18)} fontWeight="medium">
            {title}
          </SuiTypography>
        </SuiBox>
      )}
      <SuiBox display="flex" rowGap="1rem" flexDirection="column">
        {listChildRoom?.map((childRoom) => (
          <ChildRoomStyled
            key={childRoom?._id}
            expanded={expanded === childRoom?._id}
            onChange={handleChange(childRoom?._id as string)}
            sx={{}}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${childRoom?._id}-content`}
              id={`${childRoom?._id}-header`}
              sx={{
                display: 'flex',
                borderBottom: (t) =>
                  ` ${
                    expanded === childRoom?._id
                      ? `1px dashed ${t.palette.inputColors.borderColor.main}`
                      : 'none'
                  }`,
                '> .MuiAccordionSummary-content': {
                  columnGap: {
                    xs: '0.5rem',
                    md: '0.75rem',
                  },
                  margin: '1rem 0',
                  '& p': {
                    position: 'relative',
                  },
                  alignItems: 'center',
                },
                '> .MuiAccordionSummary-content p:not(:first-of-type)': {
                  paddingLeft: {
                    xs: '0.5rem',
                    sm: '0.75rem',
                  },
                },
                '> .MuiAccordionSummary-content p:not(:first-of-type)::before':
                  {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: 0,
                    width: '1.5px',
                    backgroundColor: '#BDBDBD',
                    height: '0.9375rem',
                    transform: 'translateY(-50%)',
                  },
              }}
            >
              <SuiTypography
                sx={{
                  '&::before': {
                    fontWeight: 'bold',
                  },
                  whiteSpace: 'nowrap',
                }}
                fontWeight="bold"
                fontSize="1rem"
              >
                {childRoom?.name}
              </SuiTypography>
              <Typography
                fontSize="1rem"
                sx={{
                  ...getEllipsisStyles(1),
                }}
              >
                {childRoom?.room?.name}
              </Typography>
              <Typography
                fontSize="1rem"
                sx={{
                  ...getEllipsisStyles(1),
                  minWidth: 'fit-content',
                  display: {
                    xs: 'none',
                    sm: 'block',
                  },
                }}
              >
                {childRoom?.room?.maxCapacity} khách
              </Typography>

              {[
                OrderStatus.NEW.toString(),
                OrderStatus.LOCKED.toString(),
                OrderStatus.CHECKIN.toString(),
              ].includes(getOrder(childRoom?._id || '')?.status || '') && (
                <Box ref={anchorRef}>
                  <SuiButton
                    type="button"
                    color="primary"
                    aria-label="Đổi phòng"
                    onClick={(e) => handleChangeChildroom(e, childRoom?._id)}
                    variant="outlined"
                    size="small"
                    sx={{
                      padding: '3px 5px',
                      borderRadius: '4px',
                      minHeight: 'auto',
                      '&:hover': {
                        opacity: 1,
                      },
                      fontWeight: 500,
                      fontSize: '10px',
                    }}
                    buttonColor="primary"
                  >
                    Đổi phòng
                  </SuiButton>
                  <Popper
                    transition
                    open={openListChildRoom}
                    anchorEl={anchorRef.current}
                    placement="top-start"
                    sx={{
                      zIndex: 3000,
                    }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow {...TransitionProps}>
                        <SuiBox>
                          {isLoadingGetChildRoom ? (
                            <Loader />
                          ) : (
                            <Paper
                              elevation={0}
                              variant="outlined"
                              sx={{
                                borderRadius: '0.5rem',
                                overflow: 'hidden',
                              }}
                            >
                              <ClickAwayListener
                                onClickAway={(e) => {
                                  e.stopPropagation();
                                  setOpenListChildRoom(false);
                                }}
                              >
                                <Box>
                                  {isLoadingReplaceChildRoomOrder ? (
                                    <Loader />
                                  ) : (
                                    <List
                                      sx={{
                                        width: '100%',
                                        maxWidth: '400px',
                                        minWidth: '250px',
                                        maxHeight: '300px',
                                        overflowY: 'auto',
                                        position: 'relative',
                                      }}
                                      subheader={
                                        <ListSubheader
                                          sx={{
                                            fontSize: '1rem',
                                          }}
                                          color="primary"
                                        >
                                          Phòng còn trống
                                        </ListSubheader>
                                      }
                                    >
                                      {listChildRoomCanReplace?.map((c) => {
                                        return (
                                          <ListItem
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              setChildRoomChangedSelected(
                                                (prev) => {
                                                  if (prev === c._id) return '';
                                                  return c._id;
                                                }
                                              );
                                            }}
                                            key={c._id}
                                            sx={{
                                              '.MuiListItemSecondaryAction-root':
                                                {
                                                  right: '16px',
                                                },
                                            }}
                                            secondaryAction={
                                              <Checkbox
                                                edge="end"
                                                onClick={(event) => {
                                                  event.stopPropagation();
                                                  setChildRoomChangedSelected(
                                                    (prev) => {
                                                      if (prev === c._id)
                                                        return '';
                                                      return c._id;
                                                    }
                                                  );
                                                }}
                                                checked={
                                                  childRoomChangedSelected ===
                                                  c._id
                                                }
                                                inputProps={{
                                                  'aria-labelledby': c._id,
                                                }}
                                              />
                                            }
                                            disablePadding
                                          >
                                            <ListItemButton>
                                              <ListItemIcon>
                                                <SingleBedIcon fontSize="large" />
                                              </ListItemIcon>
                                              <ListItemText
                                                id={c._id}
                                                primary={c.name}
                                              />
                                            </ListItemButton>
                                          </ListItem>
                                        );
                                      })}
                                    </List>
                                  )}

                                  <SuiBox
                                    display="flex"
                                    justifyContent="flex-end"
                                    sx={{
                                      padding: '1rem',
                                    }}
                                  >
                                    <SuiButton
                                      variant="outlined"
                                      buttonColor="secondary"
                                      sx={{ mr: 2 }}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setOpenListChildRoom(false);
                                      }}
                                      disabled={isLoadingReplaceChildRoomOrder}
                                      size="small"
                                    >
                                      Thoát
                                    </SuiButton>
                                    <SuiButton
                                      buttonColor="primary"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleReplaceChildRoom();
                                      }}
                                      size="small"
                                      loading={isLoadingReplaceChildRoomOrder}
                                      disabled={isLoadingReplaceChildRoomOrder}
                                    >
                                      Thay đổi
                                    </SuiButton>
                                  </SuiBox>
                                </Box>
                              </ClickAwayListener>
                            </Paper>
                          )}
                        </SuiBox>
                      </Grow>
                    )}
                  </Popper>
                </Box>
              )}

              {!!getOrder(childRoom?._id || '') && (
                <SuiBox
                  marginLeft="auto"
                  pr={1}
                  display="flex"
                  columnGap="8px"
                  alignItems="center"
                >
                  {renderHotelCardBtn(getOrder(childRoom?._id || ''))}
                  <Chip
                    label={
                      BOOKING_STATUS_TEXTS[
                        getOrder(childRoom?._id || '')?.status || ''
                      ]
                    }
                    sx={{
                      backgroundColor:
                        BOOKING_STATUS_COLORS[
                          getOrder(childRoom?._id || '')?.status || ''
                        ],
                      color: '#fff',
                    }}
                    onDelete={() => {
                      setSelectedOrder(getOrder(childRoom?._id || ''));
                    }}
                    deleteIcon={
                      <EditIcon
                        fontSize="small"
                        sx={{
                          color: '#fff!important',
                        }}
                      />
                    }
                  />
                </SuiBox>
              )}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                marginTop: '0.5rem',
              }}
            >
              <List
                dense
                sx={{
                  width: '100%',
                  'li:not(:last-child)': {
                    marginBottom: '1rem',
                  },
                  padding: '0 1rem',
                  span: {
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  },
                  'li div:nth-of-type(2)': {
                    textAlign: 'right',
                  },
                  'li:last-child div:nth-of-type(2)': {
                    color: (t) => t.palette.success.main,
                  },
                }}
              >
                {renderBillByOrder(childRoom?._id)}
              </List>
            </AccordionDetails>
          </ChildRoomStyled>
        ))}
      </SuiBox>
      {renderModal}
    </SuiBox>
  );
}

export default ListChildRoom;
