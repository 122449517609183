import { DateTimePickerProps } from '@mui/x-date-pickers';
import { useField } from 'formik';
import DateTimePicker from '../DateTimePicker';
import { SuiInputProps } from '../SuiInput';
import { BaseFieldProps } from './types';

interface FormikInputProps
  extends BaseFieldProps,
    Omit<DateTimePickerProps<Date>, 'value'> {
  SuiInputProps?: Partial<Omit<SuiInputProps, 'onChange' | 'value' | 'ref'>>;
}

function FormikDateTimePicker({
  name,
  helperText,
  disabled,
  placeholder,
  label = placeholder,
  hideSuccessIndicator = false,
  ...props
}: FormikInputProps) {
  const [field, meta, helpers] = useField(name);
  const { error, touched } = meta;
  const isError = !!error && touched;

  return (
    <DateTimePicker
      {...field}
      {...props}
      name={name}
      label={label}
      onChange={helpers.setValue}
      onSelectedSectionsChange={() => helpers.setTouched(true)}
      SuiInputProps={{
        name,
        helperText: isError ? error : helperText,
        error: isError,
        placeholder,
      }}
    />
  );
}

export default FormikDateTimePicker;
