import {
  PaperProps,
  useMediaQuery,
  Box,
  Paper,
  Popover,
  useTheme,
} from '@mui/material';
import { PropsWithChildren } from 'react';
import { BottomSheet } from 'react-spring-bottom-sheet';

interface ReponsivePopoverProps {
  anchorElement: HTMLElement | null;
  onDismiss: () => void;
  paperProps?: Partial<PaperProps>;
}

export default function ResponsivePopover({
  anchorElement,
  onDismiss,
  children,
  paperProps = {},
}: PropsWithChildren<ReponsivePopoverProps>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isMobile ? (
    <Box
      component={BottomSheet}
      open={!!anchorElement}
      onDismiss={onDismiss}
      sx={{ zIndex: 2000, position: 'relative' }}
    >
      <Paper
        {...paperProps}
        sx={{
          p: 1,
          width: '100%',
          backgroundColor: theme.palette.white.main,
          ...paperProps.sx,
        }}
      >
        {children}
      </Paper>
    </Box>
  ) : (
    <Popover
      open={!!anchorElement}
      anchorEl={anchorElement}
      onClose={onDismiss}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{
        ...paperProps,
        sx: {
          p: 1,
          minWidth: theme.spacing(60),
          maxWidth: theme.spacing(80),
          backgroundColor: theme.palette.white.main,
          ...paperProps.sx,
        },
      }}
    >
      {children}
    </Popover>
  );
}
