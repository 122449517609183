import { forwardRef } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface CustomProps extends Omit<NumericFormatProps, 'onChange'> {
  onChange: (event: { target: { name: string; value: number } }) => void;
  name: string;
}

const NumericFormatInput = forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, thousandSeparator = true, ...other } = props;

    return (
      <NumericFormat
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: Number(values.value),
            },
          });
        }}
        valueIsNumericString
        allowLeadingZeros={false}
        thousandSeparator={thousandSeparator}
        {...other}
      />
    );
  }
);

export default NumericFormatInput;
