import { captureException } from '@sentry/react';
import axios, { AxiosError } from 'axios';

const { VITE_BOOKDEE_DEVICE_SERVER } = import.meta.env;

const deviceLocalClient = axios.create({
  baseURL: `${VITE_BOOKDEE_DEVICE_SERVER}`,
  headers: { 'Content-Type': 'application/json' },
});

deviceLocalClient.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error: AxiosError) => {
    captureException(error, {
      extra: {
        response: error.response?.data,
        request: error.request,
        status: error.status,
      },
    });
    return Promise.reject(error);
  }
);

export default deviceLocalClient;
