interface ChildRoom {
  _id: string;
  enabled: boolean;
  name: string;
  room: string;
  lock?: ITTLockDevice;
}

export enum LockState {
  LOCKED = 0,
  UNLOCKED = 1,
  UNKNOWN = 2,
}

export interface ITTLockDevice {
  lockId: number;
  lockName: string;
  lockAlias: string;
  lockMac: string;
  electricQuantity: number;
  featureValue: string;
  hasGateway: number;
  lockData: string;
  groupId?: number;
  groupName?: string;
  date: number;
  state?: LockState;
  childRoom?: ChildRoom;
  bindDate: number;
  electricQuantityUpdateDate: number;
  passageMode: number;
  lockSound: number;
  autoLockTime: number;
}

export enum ServerRecordType {
  UNLOCK_BY_APP = 1,
  UNLOCK_BY_PASSCODE = 4,
  UNLOCK_BY_IC_CARD = 7,
  UNLOCK_BY_FINGERPRINT = 8,
  UNLOCK_BY_MERCHANICAL_KEY = 10,
  LOCK_BY_APP = 11,
  UNLOCK_BY_GATEWAY = 12,
  OPEN_FROM_INSIDE = 32,
  LOCK_BY_FINGERPRINT = 33,
  LOCK_BY_PASSCODE = 34,
  LOCK_BY_IC_CARD = 35,
  LOCK_BY_MERCHANICAL_KEY = 36,
  REMOTE_CONTROL = 37,
  AUTO_LOCK = 45,
  UNLOCK_BY_UNLOCK_KEY = 46,
  LOCK_BY_LOCK_KEY = 47,
  SYSTEM_LOCK = 48, // Caused by, for example: Using INVALID Passcode/Fingerprint/Card several times
  UNLOCK_BY_HOTEL_CARD = 49,
  UNLOCKED_DUE_TO_HIGH_TEMPERATURE = 50,
  TRY_TO_UNLOCK_WITH_A_DELETED_CARD = 51,
}

export interface LockHistory {
  recordId: number;
  lockId: number;
  recordType: ServerRecordType;
  success: 0 | 1;
  username: string;
  lockDate: number;
  keyboardPwd?: string;
}
export enum ServerKeyboardPwdType {
  PERMANENT = 2,
  PERIOD = 3,
}

export interface PassCode {
  endDate: number;
  sendDate: number;
  keyboardPwdId: number;
  nickName: string;
  keyboardPwdType: ServerKeyboardPwdType;
  lockId: number;
  keyboardPwdVersion: number;
  isCustom: 0 | 1;
  keyboardPwdName: string;
  keyboardPwd: string;
  startDate: number;
  senderUsername: string;
  receiverUsername: string;
  status: number;
}

// IC Card
export enum EnumCardType {
  NORMAL = 1,
  CYCLIC = 2,
}

export interface CyclicConfig {
  startDate: number; // The time when it becomes valid, in minute, for example 480 for 8:00
  endDate: number; // The time when it becomes valid is expired, in minute, for example 1080 for 18:00
  weekDay: number; // Week day：1-7, 1-Monday, 2-Tuesday...7-Sunday
}
export interface ICIdentityCard {
  cardId: number;
  lockId: number;
  cardNumber: string;
  cardName: string;
  startDate: number;
  endDate: number;
  createDate: number;
  senderUsername: string;
  cardType: number;
  cyclicConfig: CyclicConfig[];
}
