import Grid2 from '@mui/material/Unstable_Grid2';
import FormikDateTimePicker from 'components/Formik/FormikDateTimePicker';
import FormikInput from 'components/Formik/FormikInput';
import FormikSelect from 'components/Formik/FormikSelect';
import NumericFormatInput from 'components/NumericFormatInput';
import SuiBox from 'components/SuiBox';
import SuiModal from 'components/SuiModal';
import { isValid } from 'date-fns';
import { Formik, FormikProps } from 'formik';
import { useCallback, useMemo, useRef } from 'react';
import { ICIdentityCard } from 'shared/models';
import * as Yup from 'yup';

const fieldTypeOptions = [
  { label: 'Cả ngày', value: 1 },
  { label: 'Theo thời gian cố định trong ngày (waiting)', value: 4 },
];

const cardTypeOptions = [
  { label: 'Theo khoảng thời gian', value: '1' },
  { label: 'Không giới hạn ', value: '2' },
];

interface LockICCardFormCreateProps {
  lockId: number;
  deviceName: string;
  onClose: () => void;
  onAddICCard: (values: Partial<ICIdentityCard>) => void;
  loading: boolean;
}

interface LockICCardFormCreateValues {
  lockId: number;
  deviceName: string;
  cardType: number;
  startDate: Date;
  endDate: Date;
  cardNumber: string;
  cardName: string;
  timeType: string;
}
const icCardFormValidationSchema = Yup.object().shape({
  lockId: Yup.string().required('Vui nhâp mã khóa'),
  deviceName: Yup.string().required('Vui lòng nhập tên thiết bị'),
  cardName: Yup.string().required('Vui lòng nhập tên thẻ từ'),
  cardNumber: Yup.string().required('Vui lòng nhập mã thẻ từ'),
  startDate: Yup.string()
    .test({
      name: 'startDate',
      message: 'Ngày bắt đầu không hợp lệ',
      test: (value: any, ctx) => {
        if (!isValid(new Date(value))) {
          return ctx.createError({
            message: 'Thời gian không hợp lệ',
          });
        }
        const { endDate } = ctx.parent as any;
        if (!endDate) {
          return true;
        }
        if (new Date(value) > new Date(endDate)) {
          return ctx.createError({
            message: 'Thời gian bắt đầu phải nhỏ hơn thời gian kết thúc',
          });
        }
        return true;
      },
    })
    .required('Vui lòng nhập ngày bắt đầu'),

  endDate: Yup.string()
    .test({
      name: 'endDate',
      message: 'Ngày kết thúc không hợp lệ',
      test: (value: any, ctx) => {
        if (!isValid(new Date(value))) {
          return ctx.createError({
            message: 'Thời gian không hợp lệ',
          });
        }

        return true;
      },
    })
    .required('Vui lòng nhập ngày hết hạn'),
});

function LockICCardFormCreate(props: LockICCardFormCreateProps) {
  const { onClose, onAddICCard, loading, lockId, deviceName } = props;

  const formikRef = useRef<FormikProps<LockICCardFormCreateValues> | null>(
    null
  );
  const initFormValues = useMemo(() => {
    return {
      lockId: +lockId,
      deviceName,
      cardType: 1,
      startDate: new Date(),
      endDate: new Date(),
      cardNumber: '',
      cardName: '',
      timeType: '1',
    } as LockICCardFormCreateValues;
  }, [deviceName, lockId]);

  const handleAddNewICCard = useCallback(
    (values: LockICCardFormCreateValues) => {
      onAddICCard({
        ...values,
        startDate:
          values.timeType === '1' ? new Date(values.startDate).getTime() : 0,
        endDate:
          values.timeType === '1' ? new Date(values.endDate).getTime() : 0,
      });
    },
    [onAddICCard]
  );

  const handleOnclose = useCallback(
    (event: unknown, reason: 'backdropClick' | 'escapeKeyDown') => {
      if (reason !== 'backdropClick') {
        onClose();
      }
    },
    [onClose]
  );

  return (
    <Formik
      enableReinitialize
      onSubmit={handleAddNewICCard}
      initialValues={initFormValues}
      innerRef={formikRef}
      validationSchema={icCardFormValidationSchema}
    >
      {({ values }) => (
        <SuiModal
          showCloseIcon={false}
          backText="Về trang trước"
          open
          confirmText="Tạo"
          cancelText="Thoát"
          onConfirm={() => formikRef.current?.submitForm()}
          title="Tạo thẻ từ"
          loadingOverlay={loading}
          onClose={handleOnclose}
          onCancel={onClose}
        >
          <SuiBox>
            <SuiBox
              sx={{
                borderTop: '1px dashed #BDBDBD',
              }}
              pt={1.5}
            >
              <Grid2
                container
                mb={3}
                width="100%"
                rowSpacing={{ xs: 2 }}
                columnSpacing={{ xs: 1, md: 2 }}
              >
                <Grid2 xs={12} md={12}>
                  <FormikInput
                    label="Tên thiết bị"
                    readOnly
                    name="deviceName"
                  />
                </Grid2>
                <Grid2 xs={12} md={12}>
                  <FormikInput
                    name="cardName"
                    placeholder="Nhập tên thẻ từ"
                    label="Tên thẻ từ"
                  />
                </Grid2>
                <Grid2 xs={12} md={12}>
                  <FormikInput
                    name="cardNumber"
                    placeholder="Nhập mã số thẻ từ"
                    label="Mã số thẻ từ"
                    inputComponent={NumericFormatInput as any}
                    inputProps={{
                      inputMode: 'numeric',
                      thousandSeparator: false,
                    }}
                  />
                </Grid2>
                <Grid2 xs={12} md={12}>
                  <FormikSelect
                    name="timeType"
                    label="Thời gian hiệu lực"
                    options={cardTypeOptions}
                    placeholder="Chọn thời gian hiệu lực"
                  />
                </Grid2>
                {values.timeType === '1' && (
                  <>
                    <Grid2 xs={12} md={12}>
                      <FormikDateTimePicker
                        name="startDate"
                        label="Chọn thời gian bắt đầu"
                      />
                    </Grid2>
                    <Grid2 xs={12} md={12}>
                      <FormikDateTimePicker
                        name="endDate"
                        label="Chọn thời gian hết hạn"
                      />
                    </Grid2>
                  </>
                )}

                <Grid2 xs={12} md={12}>
                  <FormikSelect
                    name="cardType"
                    label="Thời gian hiệu lực trong ngày"
                    options={fieldTypeOptions}
                    placeholder="Chọn thời gian hiệu lực trong ngày"
                  />
                </Grid2>
              </Grid2>
            </SuiBox>
          </SuiBox>
        </SuiModal>
      )}
    </Formik>
  );
}

export default LockICCardFormCreate;
